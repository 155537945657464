import React, {
    useContext,
    useState,
    useCallback,
    useEffect
} from 'react';
import {
    get as _get,
    set as _set,
    filter as _filter,
    isEmpty as _isEmpty,
    pullAt as _pullAt
} from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { CreditService } from 'e1p-capability-gateway';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useModal } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { AddCreditExceptionComponent, AddCreditFixComponent } from 'e1p-capability-policyjob-react';
import metadata from './CreditReportsComponent.metadata.json5';
import messages from './CreditReportsComponent.messages';

function CreditReportsComponent(props) {
    const modalApi = useModal();
    const {
        submissionVM,
        updateWizardData,
        viewOnlyMode,
        lineOfBusiness,
        lobName,
        authUserData,
        id,
        onValidate,
        setOverlappingException
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [creditReportLoaded, setCreditReportLoaded] = useState(true);
    const [pniCreditReportToUse, setPniCreditReportToUse] = useState('');
    const [sniCreditReportToUse, setSniCreditReportToUse] = useState('');
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const { authHeader } = useAuthentication();
    const jobTypeCode = _get(submissionVM, 'baseData.jobType.value.code', '');
    const jobNumber = jobTypeCode !== 'Submission' ? _get(submissionVM, 'jobID.value') : _get(submissionVM, 'quoteID.value');

    const forceCallCredit = (namedInsured, niCreditDetails) => {
        setCreditReportLoaded(false);
        CreditService.forceOrderCreditReport(
            jobNumber,
            namedInsured.value,
            authHeader
        ).then((creditReport) => {
            if (creditReport) {
                niCreditDetails.value.push(creditReport);
            }

            setCreditReportLoaded(true);
        }).catch(() => {
            setCreditReportLoaded(true);
        });
    };

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [submissionVM, id, onValidate, isComponentValid]);

    const dateRangeOverlaps = (aStart, aEnd, bStart, bEnd) => {
        if (aStart < bStart && bStart < aEnd) {return true;} // b starts in a

        if (aStart < bEnd && bEnd < aEnd) {return true;} // b ends in a

        if (bStart < aStart && aEnd < bEnd) {return true;} // a in b

        if (aStart === bStart || aEnd === bEnd) {return true;}

        return false;
    };

    const getISODate = (dateObj) => {
        if(!dateObj) {
            return undefined;
        }

        const day = dateObj.day.toString().length === 1 ? `0${dateObj.day}` : dateObj.day;
        const actualMonth = dateObj.month + 1;
        const month = actualMonth.toString().length === 1 ? `0${actualMonth}` : actualMonth;

        return `${dateObj.year}-${month}-${day}`;
    }

    // Helper function that checks all exceptions for overlapping dates
    const exceptionDateOverlapping = useCallback((exceptions) => {
        let i = 0;
        let j = 0;

        // only checks if > 1 exceptions
        if (exceptions.length > 1) {
            // starts with i as first exception and j as second exception
            for (i = 0; i < exceptions.length - 1; i += 1) { // interates from the first exception to the second to last exception
                for (j = i + 1; j < exceptions.length; j += 1) { // interates from the second exception to the last exception
                    // checks start and end dates of both exceptions to see if they overlap
                    const iStartDate = exceptions[i].startDate.isodate_Ext ?? getISODate(exceptions[i].startDate);
                    const iEndDate = exceptions[i].endDate.isodate_Ext ?? getISODate(exceptions[i].endDate);
                    
                    const jStartDate = exceptions[j].startDate.isodate_Ext ?? getISODate(exceptions[j].startDate);
                    const jEndDate = exceptions[j].endDate.isodate_Ext ?? getISODate(exceptions[j].endDate);

                    if (
                        dateRangeOverlaps(
                            iStartDate, iEndDate,
                            jStartDate, jEndDate
                        )
                    ) {
                        // as soon as a date overlaps we return true
                        setOverlappingException(true);

                        return true;
                    }
                }
            }
        }

        setOverlappingException(false);

        return false;
    }, [setOverlappingException]);

    const showAddException = useCallback(async (vm, editing = false) => {
        const componentProps = {
            title: 'Add Exception',
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            creditVM: vm,
            transactionEffectiveDate: submissionVM.baseData.effectiveDate ? submissionVM.baseData.effectiveDate : submissionVM.baseData.periodStartDate,
            viewModelService,
            editing
        };

        return modalApi.showModal(<AddCreditExceptionComponent {...componentProps} />);
    }, [submissionVM.baseData.periodStartDate, submissionVM.baseData.effectiveDate, viewModelService, modalApi]);


    const showAddFix = useCallback(async (vm) => {
        const componentProps = {
            title: 'Add Fix',
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            creditVM: vm,
            transactionEffectiveDate: submissionVM.baseData.periodStartDate,
            viewModelService
        };

        return modalApi.showModal(<AddCreditFixComponent {...componentProps} />);
    }, [submissionVM.baseData.periodStartDate, viewModelService, modalApi]);

    const addException = async (creditList) => {
        const {
            _dtoName,
            _xCenter
        } = creditList;
        const creditVM = viewModelService.create({ type: 'exception' }, _xCenter, _dtoName);
        const newCreditException = await showAddException(creditVM);

        creditList.value.push(newCreditException.value);

        const exceptions = _filter(creditList.value, (creditReport) => creditReport.type === 'exception');
        const overlappingExceptions = exceptionDateOverlapping(exceptions);

        if (newCreditException && overlappingExceptions) {
            modalApi.showAlert({
                status: 'error',
                icon: 'mi-error-outline',
                title: messages.overlappingExceptionTitle,
                message: messages.overlappingExceptionMessage
            });
        }

        updateWizardData(submissionVM);
    };

    // Function to edit an exception after it is added.
    // Utilizes same modal as add exception.
    // After editing the exception all exceptions must be
    // compared to ensure no overlapping dates.
    const editException = useCallback(async (creditList, index) => {
        const editedException = await showAddException(creditList.children[index], true);
        
        _set(creditList.value, index, editedException.value);

        const exceptions = _filter(creditList.value, (creditReport) => creditReport.type === 'exception');
        const overlappingExceptions = exceptionDateOverlapping(exceptions);

        if (overlappingExceptions) {
            modalApi.showAlert({
                status: 'error',
                icon: 'mi-error-outline',
                title: messages.overlappingExceptionTitle,
                message: messages.overlappingExceptionMessage
            });
        }

        updateWizardData(submissionVM);
    }, [exceptionDateOverlapping, showAddException, submissionVM, updateWizardData, modalApi]);

    const deleteException = useCallback((creditPath, index) => {
        // get creditlist
        const creditList = _get(submissionVM, creditPath, []);

        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: e1pCommonMessages.removeCreditTitle,
            message: e1pCommonMessages.removeCreditDescription,
            confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'CREDIT REPORT' }),
            cancelButtonText: e1pCommonMessages.cancel
        }).then((results) => {
            if (results !== 'cancel') {
                if(!_isEmpty(creditList)) {
                    _pullAt(creditList, index);
                    _set(submissionVM, creditPath, creditList);
                    updateWizardData(submissionVM);

                    // after removing the overlapping record, check again for overlapping
                    const exceptions = _filter(creditList, (creditReport) => creditReport.type === 'exception');
                    const overlappingExceptions = exceptionDateOverlapping(exceptions);

                    setOverlappingException(overlappingExceptions);
                }
            }
        });
    }, [exceptionDateOverlapping, modalApi, setOverlappingException, submissionVM, translator, updateWizardData]);

    const addFix = async (creditList, index) => {
        const copyOfSelectedReport = viewModelService.clone(creditList.children[index]);
        const modifiedCreditReport = await showAddFix(copyOfSelectedReport);

        creditList.value.push(modifiedCreditReport.value);
        updateWizardData(submissionVM);
    };


    const getPNIName = () => lineOfBusiness.primaryNamedInsured.person.displayName.value;
    
    const getSNIName = useCallback(() => {
        if (lineOfBusiness.secondaryNamedInsured.person !== undefined) {
            return lineOfBusiness.secondaryNamedInsured.person.displayName.value;
        }

        return '';
    }, [lineOfBusiness.secondaryNamedInsured.person]);

    const getPNISelectedValues = (index) => ([
            {
                id: `pniCreditSelected${index}`,
                defaultMessage: ''
            }
        ]);

    const getSNISelectedValues = (index) => ([
            {
                id: `sniCreditSelected${index}`,
                defaultMessage: ''
            }
        ]);

    const pniSelected = (e) => {
        setPniCreditReportToUse(e);
    };

    const sniSelected = (e) => {
        setSniCreditReportToUse(e);
    };

    const hasBasicCreditPermission = authUserData?.permissions_Ext.includes('basiccreditpermission');
    const hasIntermidiateCreditPermission = authUserData?.permissions_Ext.includes('intermidiatecreditpermission');
    const hasAdvancedCreditPermission = authUserData?.permissions_Ext.includes('advancedcreditpermission');
    const canViewCreditScore = authUserData?.permissions_Ext.includes('viewcbis_ext');

    const generateOverrides = useCallback(() => {
        const overrideProps = {};

        lineOfBusiness.creditDetails.pnicreditDetails.children.forEach((creditReport, index) => {
            if (!creditReport.type.value) {
                overrideProps[`pniTypeId${index}`] = {
                    visible: false
                };
                overrideProps[`pniOrderedTypeId${index}`] = {
                    value: 'Ordered'
                };
            } else {
                overrideProps[`pniOrderedTypeId${index}`] = {
                    visible: false
                };
            }

            overrideProps[`pniSelectedId${index}`] = {
                availableValues: getPNISelectedValues(index),
                onValueChange: (e) => { pniSelected(e); },
                value: `pniCreditSelected${index}` === pniCreditReportToUse ? `pniCreditSelected${index}` : '',
                visible: !viewOnlyMode && hasAdvancedCreditPermission
            };
            overrideProps[`pniCreditExceptionEditId${index}`] = {
                visible: !viewOnlyMode && hasAdvancedCreditPermission && creditReport.type.value !== undefined && creditReport.type.value.code === 'exception',
                onClick: () => { editException(_get(submissionVM, `lobData.${lobName}.creditDetails.pnicreditDetails`), index); }

            };
            overrideProps[`pniCreditExceptionDeleteId${index}`] = {
                visible: !viewOnlyMode && hasAdvancedCreditPermission
                    && creditReport.type.value !== undefined
                    && creditReport.type.value.code === 'exception'
                    && _get(creditReport, 'value.creditUpdateKey') === undefined,
                onClick: () => { deleteException(`value.lobData.${lobName}.creditDetails.pnicreditDetails`, index); }

            };
            overrideProps[`pniReportDateId${index}`] = {
                dateDTO: creditReport.reportOrderDate
            };
            overrideProps[`pniReportScoreId${index}`] = {
                visible: canViewCreditScore
            };
        });
        lineOfBusiness.creditDetails.snicreditDetails.children.forEach((creditReport, index) => {
            if (!creditReport.type.value) {
                overrideProps[`sniTypeId${index}`] = {
                    visible: false,
                };
                overrideProps[`sniOrderedTypeId${index}`] = {
                    value: 'Ordered'
                };
            } else {
                overrideProps[`sniOrderedTypeId${index}`] = {
                    visible: false
                };
            }

            overrideProps[`sniSelectedId${index}`] = {
                availableValues: getSNISelectedValues(index),
                onValueChange: (e) => { sniSelected(e); },
                value: `sniCreditSelected${index}` === sniCreditReportToUse ? `sniCreditSelected${index}` : '',
                visible: !viewOnlyMode && hasAdvancedCreditPermission
            };
            overrideProps[`sniCreditExceptionEditId${index}`] = {
                visible: !viewOnlyMode && hasAdvancedCreditPermission&& creditReport.type.value !== undefined && creditReport.type.value.code === 'exception',
                onClick: () => { editException(_get(submissionVM, `lobData.${lobName}.creditDetails.snicreditDetails`), index); }
            };
            overrideProps[`sniCreditExceptionDeleteId${index}`] = {
                visible: !viewOnlyMode && hasAdvancedCreditPermission
                    && creditReport.type.value !== undefined
                    && creditReport.type.value.code === 'exception'
                    && _get(creditReport, 'value.creditUpdateKey') === undefined,
                onClick: () => { deleteException(`value.lobData.${lobName}.creditDetails.snicreditDetails`, index); }

            };
            overrideProps[`sniReportDateId${index}`] = {
                dateDTO: creditReport.reportOrderDate
            };
            overrideProps[`sniReportScoreId${index}`] = {
                visible: canViewCreditScore
            };
        });

        return overrideProps;
    }, [
        lineOfBusiness.creditDetails.pnicreditDetails.children,
        lineOfBusiness.creditDetails.snicreditDetails.children,
        pniCreditReportToUse, viewOnlyMode, hasAdvancedCreditPermission,
        canViewCreditScore, editException, submissionVM, lobName,
        sniCreditReportToUse, deleteException
    ]);


    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            autoComplete: false
        },
        creditReportsPageLoadingIndicator: {
            loaded: creditReportLoaded,
            text: translator(messages.orderingCreditReportsMessage)
        },
        creditReportsPageContainer: {
            visible: creditReportLoaded
        },
        creditReportsContainer: {
            visible: hasBasicCreditPermission
                || hasIntermidiateCreditPermission
                || hasAdvancedCreditPermission
        },
        appliedISGId: {
            visible: hasIntermidiateCreditPermission
                || hasAdvancedCreditPermission,
            // IAP-7: Rating
            //   PA does not get InsuranceScoreClassRatedEvent, as it is mutually exclusive with UnderwritingRiskScoreEvent
            //   - so there will be no data in AppliedScoreClass, CalculatedScoreClass, or InsuranceScoreClassUncapped on the DTO.
            path: _get(submissionVM, `lobData[${lobName}]creditDetails.underwritingRiskScore.value`, undefined)
                ? `lobData.${lobName}.creditDetails.underwritingRiskScore`
                : `lobData.${lobName}.creditDetails.appliedScoreClass`
        },
        rateProcInstructionId: {
            visible: hasAdvancedCreditPermission,
            path: _get(lineOfBusiness, 'creditDetails.rateProcessInstruction.value') !== undefined
                ? `lobData.${lobName}.creditDetails.rateProcessInstruction`
                : `lobData.${lobName}.creditDetails.pnicreditDetails.children[0].rateProcessInstruction`
        },
        eventTypeId: {
            visible: hasAdvancedCreditPermission,
            path: `lobData.${lobName}.creditDetails.eventType`
        },
        calculatedISGId: {
            visible: hasAdvancedCreditPermission,
            path: `lobData.${lobName}.creditDetails.calculatedScoreClass`
        },
        sniCreditReportsContainer: {
            visible: lobName === 'personalAuto_EA' && lineOfBusiness.secondaryNamedInsured.person !== undefined
        },
        pniCreditReportOrderingInfo: {
            value: getPNIName(),
        },
        sniCreditReportOrderingInfo: {
            value: getSNIName(),
        },
        pniCreditReportsTable: {
            path: `lobData.${lobName}.creditDetails.pnicreditDetails.children`
        },
        sniCreditReportsTable: {
            path: `lobData.${lobName}.creditDetails.snicreditDetails.children`
        },
        pniForceOrderCreditId: {
            onClick: () => {
                forceCallCredit(
                    _get(submissionVM, `lobData.${lobName}.primaryNamedInsured`),
                    _get(submissionVM, `lobData.${lobName}.creditDetails.pnicreditDetails`)
                );
            },
            visible: !viewOnlyMode
        },
        sniForceOrderCreditId: {
            onClick: () => {
                forceCallCredit(
                    _get(submissionVM, `lobData.${lobName}.secondaryNamedInsured`),
                    _get(submissionVM, `lobData.${lobName}.creditDetails.snicreditDetails`)
                );
            },
            visible: !viewOnlyMode && lineOfBusiness.secondaryNamedInsured.person !== undefined
        },
        pniAddExceptionId: {
            onClick: () => {
                addException(lineOfBusiness.creditDetails.pnicreditDetails);
            },
            visible: !viewOnlyMode
        },
        sniAddExceptionId: {
            onClick: () => {
                addException(lineOfBusiness.creditDetails.snicreditDetails);
            },
            visible: !viewOnlyMode
        },
        pniAddFixId: {
            onClick: () => {
                const indexOfSelected = parseInt(pniCreditReportToUse.match(/\d+$/)[0], 10); // parses out index at the end of the id

                addFix(
                    lineOfBusiness.creditDetails.pnicreditDetails,
                    indexOfSelected
                );
            },
            visible: !viewOnlyMode,
            disabled: pniCreditReportToUse === '' || lineOfBusiness.creditDetails.pnicreditDetails.children[parseInt(pniCreditReportToUse.match(/\d+$/)[0], 10)].type.value?.code === 'exception'
        },
        sniAddFixId: {
            onClick: () => {
                const indexOfSelected = parseInt(sniCreditReportToUse.match(/\d+$/)[0], 10); // parses out index at the end of the id

                addFix(lineOfBusiness.creditDetails.snicreditDetails, indexOfSelected);
            },
            visible: !viewOnlyMode,
            disabled: sniCreditReportToUse === '' || lineOfBusiness.creditDetails.snicreditDetails.children[parseInt(sniCreditReportToUse.match(/\d+$/)[0], 10)].type.value?.code === 'exception'
        },
        pniFooterButtonsComponent: {
            visible: !viewOnlyMode && hasAdvancedCreditPermission
        },
        sniFooterButtonsComponent: {
            visible: !viewOnlyMode && hasAdvancedCreditPermission
        },
        pniReportScoreLabelId: {
            visible: canViewCreditScore
        },
        sniReportScoreLabelId: {
            visible: canViewCreditScore
        },
        pniSelectLabelId: {
            visible: !viewOnlyMode && hasAdvancedCreditPermission
        },
        sniSelectLabelId: {
            visible: !viewOnlyMode && hasAdvancedCreditPermission
        },
        ...generateOverrides()

    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            onModelChange={updateWizardData}
            onValidationChange={setComponentValidation}
        />
    );
}

CreditReportsComponent.propTypes = {
    submissionVM: PropTypes.shape({
        baseData: PropTypes.shape({
            periodStartDate: PropTypes.shape({})
        }),
        lobData: PropTypes.shape({})
    }).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    viewOnlyMode: PropTypes.bool,
    lineOfBusiness: PropTypes.shape({
        creditDetails: PropTypes.shape({
            pnicreditDetails: PropTypes.shape({
                children: PropTypes.arrayOf(PropTypes.shape({}))
            }),
            snicreditDetails: PropTypes.shape({
                children: PropTypes.arrayOf(PropTypes.shape({}))
            })
        }),
        primaryNamedInsured: PropTypes.shape({
            person: PropTypes.shape({
                displayName: PropTypes.shape({
                    value: PropTypes.string
                })
            })
        }),
        secondaryNamedInsured: PropTypes.shape({
            person: PropTypes.shape({
                displayName: PropTypes.shape({
                    value: PropTypes.string
                })
            })
        })
    }).isRequired,
    lobName: PropTypes.string.isRequired,
    authUserData: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    setOverlappingException: PropTypes.func,
};

CreditReportsComponent.defaultProps = {
    viewOnlyMode: false,
    setOverlappingException: undefined
};

export default CreditReportsComponent;
