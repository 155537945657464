// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__Summary__cancellationEffectiveDate{font-size:var(--font-xxl);font-weight:var(--font-weight-regular)}.app__Summary__cancellationRefundAmount{--fieldComponent-control-readonly-font-weight:var(--font-weight-regular);--fieldComponent-control-readonly-font-size:var(--font-xxl)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/Reinstatement/Summary/Summary.module.scss"],"names":[],"mappings":"AAAA,yCAEC,yBAAA,CADA,sCACA,CAGD,wCACC,wEAAA,CACA,2DAAA","sourcesContent":[".cancellationEffectiveDate {\n\tfont-weight: var(--font-weight-regular);\n\tfont-size: var(--font-xxl);\n}\n\n.cancellationRefundAmount {\n\t--fieldComponent-control-readonly-font-weight: var(--font-weight-regular);\n\t--fieldComponent-control-readonly-font-size: var(--font-xxl);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancellationEffectiveDate": "app__Summary__cancellationEffectiveDate",
	"cancellationRefundAmount": "app__Summary__cancellationRefundAmount"
};
export default ___CSS_LOADER_EXPORT___;
