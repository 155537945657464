// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ThankYouPage__producerEngageContainer{background-color:#fff;padding-bottom:0}.app__ThankYouPage__producerEngageContainer>div{padding-top:0}.app__ThankYouPage__thankYouMessage{font-size:var(--font-xl);font-weight:var(--font-weight-normal-bold)}.app__ThankYouPage__exitToDashBoard{align-items:flex-end;display:flex;flex-direction:column}.app__ThankYouPage__headerMargin{flex:1 1;padding:var(--GW-SPACING-8) 0 0 0}.app__ThankYouPage__wizardHeaderMargin{margin:var(--GW-SPACING-8) 0 0 0}.app__ThankYouPage__flexRow{align-content:center;align-items:center;display:flex}.app__ThankYouPage__printButtonClass{margin-top:var(--GW-SPACING-10)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-quoteandbind-eh-react/pages/common/ThankYouPage/ThankYouPage.module.scss"],"names":[],"mappings":"AAAA,4CAEE,qBAAA,CADA,gBACA,CACA,gDACE,aAAA,CAIJ,oCAEE,wBAAA,CADA,0CACA,CAGF,oCACE,oBAAA,CAEA,YAAA,CADA,qBACA,CAGF,iCAEE,QAAA,CADA,iCACA,CAGF,uCACE,gCAAA,CAGF,4BAGE,oBAAA,CADA,kBAAA,CADA,YAEA,CAGF,qCACE,+BAAA","sourcesContent":[".producerEngageContainer {\n  padding-bottom: 0;\n  background-color: white;\n  > div {\n    padding-top: 0;\n  }\n}\n\n.thankYouMessage {\n  font-weight: var(--font-weight-normal-bold);\n  font-size: var(--font-xl);\n}\n\n.exitToDashBoard {\n  align-items: flex-end;\n  flex-direction: column;\n  display: flex;\n}\n\n.headerMargin {\n  padding: var(--GW-SPACING-8) 0px 0px 0px;\n  flex: 1;\n}\n\n.wizardHeaderMargin {\n  margin: var(--GW-SPACING-8) 0px 0px 0px;\n}\n\n.flexRow {\n  display: flex;\n  align-items: center;\n  align-content: center;\n}\n\n.printButtonClass {\n  margin-top: var(--GW-SPACING-10);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"producerEngageContainer": "app__ThankYouPage__producerEngageContainer",
	"thankYouMessage": "app__ThankYouPage__thankYouMessage",
	"exitToDashBoard": "app__ThankYouPage__exitToDashBoard",
	"headerMargin": "app__ThankYouPage__headerMargin",
	"wizardHeaderMargin": "app__ThankYouPage__wizardHeaderMargin",
	"flexRow": "app__ThankYouPage__flexRow",
	"printButtonClass": "app__ThankYouPage__printButtonClass"
};
export default ___CSS_LOADER_EXPORT___;
