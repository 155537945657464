// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__AccountDetails__accountIcon{border-radius:100%;font-size:var(--GW-FONT-SIZE-3)!important;padding:var(--GW-SPACING-1);vertical-align:middle}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/Accounts/AccountDetails/AccountDetails.module.scss"],"names":[],"mappings":"AAAA,kCAEI,kBAAA,CADA,yCAAA,CAGA,2BAAA,CADA,qBACA","sourcesContent":[".accountIcon {\n    font-size: var(--GW-FONT-SIZE-3) !important;\n    border-radius: 100%;\n    vertical-align: middle;\n    padding: var(--GW-SPACING-1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accountIcon": "app__AccountDetails__accountIcon"
};
export default ___CSS_LOADER_EXPORT___;
