// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__MenuBarComponent__menuBar{background:var(--msa-primary-blue);box-shadow:var(--GW-SHADOW-2);display:flex;height:3rem;padding:0 var(--GW-SPACING-6);position:relative}.app__MenuBarComponent__menuBar a{padding:var(--GW-SPACING-3) var(--GW-SPACING-5);text-decoration:none}.app__MenuBarComponent__menuBar a,.app__MenuBarComponent__menuBar a:hover{color:var(--GW-TEXT-COLOR-3)}.app__MenuBarComponent__menuBar a:hover{background-color:var(--GW-BRAND-COLOR-1-GRADIENT-HOVER)}.app__MenuBarComponent__menuBar a.app__MenuBarComponent__activeNavItem{background-color:var(--GW-FOCUS-COLOR)}.app__MenuBarComponent__menuBar .app__MenuBarComponent__navIcon{vertical-align:text-bottom}@media print{.app__MenuBarComponent__menuBar{display:none}}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/Components/MenuBar/MenuBarComponent.module.scss"],"names":[],"mappings":"AAAA,gCACI,kCAAA,CACA,6BAAA,CACA,YAAA,CACA,WAAA,CACA,6BAAA,CACA,iBAAA,CACA,kCAEE,+CAAA,CACA,oBAAA,CACA,0EAHA,4BAKE,CAFF,wCACE,uDACA,CAGJ,uEACE,sCAAA,CAGF,gEACE,0BAAA,CAIJ,aACE,gCACE,YAAA,CAAA","sourcesContent":[".menuBar {\n    background: var(--msa-primary-blue);\n    box-shadow: var(--GW-SHADOW-2);\n    display: flex;\n    height: 3rem;\n    padding: 0 var(--GW-SPACING-6);\n    position: relative;\n    a {\n      color: var(--GW-TEXT-COLOR-3);\n      padding: var(--GW-SPACING-3) var(--GW-SPACING-5);\n      text-decoration: none;\n      &:hover {\n        background-color: var(--GW-BRAND-COLOR-1-GRADIENT-HOVER);\n        color: var(--GW-TEXT-COLOR-3);\n      }\n    }\n    a.activeNavItem {\n      background-color: var(--GW-FOCUS-COLOR);\n      /* border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BRAND-COLOR-3);*/\n    }\n    .navIcon {\n      vertical-align: text-bottom;\n    }\n  }\n\n  @media print {\n    .menuBar {\n      display: none;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuBar": "app__MenuBarComponent__menuBar",
	"activeNavItem": "app__MenuBarComponent__activeNavItem",
	"navIcon": "app__MenuBarComponent__navIcon"
};
export default ___CSS_LOADER_EXPORT___;
