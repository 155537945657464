// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__EAUWIssuesComponent__uwIssueContainer{padding-bottom:var(--GW-SPACING-5)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/EAUWIssuesComponent/EAUWIssuesComponent.module.scss"],"names":[],"mappings":"AAAA,4CACE,kCAAA","sourcesContent":[".uwIssueContainer {\n  padding-bottom: var(--GW-SPACING-5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uwIssueContainer": "app__EAUWIssuesComponent__uwIssueContainer"
};
export default ___CSS_LOADER_EXPORT___;
