// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__OpeningProtectionComponent__optionContainer{margin:1rem 0 4rem}.app__OpeningProtectionComponent__shutterSelectMargin div[class*=\"tippy-box jut__Tooltip__tooltip\"]{max-width:calc(var(--GW-SPACING-6)*25)!important;width:100%!important}.app__OpeningProtectionComponent__shutterSelectMargin div[class*=\"tippy-box jut__Tooltip__tooltip\"] ul{margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/EHOpeningProtectionComponent/OpeningProtectionComponent.module.scss"],"names":[],"mappings":"AAAA,kDACE,kBAAA,CAIA,oGAEE,gDAAA,CADA,oBACA,CAEA,uGACE,eAAA","sourcesContent":[".optionContainer {\n  margin: 1rem 0 4rem;\n}\n\n.shutterSelectMargin {\n  div[class*='tippy-box jut__Tooltip__tooltip'] {\n    width: 100% !important;\n    max-width: calc(var(--GW-SPACING-6) * 25) !important;\n\n    ul {\n      margin-bottom: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionContainer": "app__OpeningProtectionComponent__optionContainer",
	"shutterSelectMargin": "app__OpeningProtectionComponent__shutterSelectMargin"
};
export default ___CSS_LOADER_EXPORT___;
