// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__EditContacts__buttonFooter{margin-top:var(--gw-buttonFooter-margin-top);text-align:right;width:var(--gw-buttonFooter-width)}.app__EditContacts__saveChanges{--gw-margin-left:10px;margin-left:var(--gw-margin-left)}.app__EditContacts__radioButtonContainer{grid-template-areas:\". radiobutton\"}.app__EditContacts__radioButtonContent{grid-area:radiobutton}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/Accounts/EditContacts/EditContacts.module.scss"],"names":[],"mappings":"AAAA,iCAGI,4CAAA,CADA,gBAAA,CADA,kCAEA,CAEJ,gCACI,qBAAA,CACA,iCAAA,CAEJ,yCACI,mCAAA,CAEJ,uCACI,qBAAA","sourcesContent":[".buttonFooter{\n    width: var(--gw-buttonFooter-width);\n    text-align: right;\n    margin-top: var(--gw-buttonFooter-margin-top);\n}\n.saveChanges {\n    --gw-margin-left: 10px;\n    margin-left: var(--gw-margin-left);\n}\n.radioButtonContainer {\n    grid-template-areas: \". radiobutton\";\n}\n.radioButtonContent {\n    grid-area: radiobutton;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonFooter": "app__EditContacts__buttonFooter",
	"saveChanges": "app__EditContacts__saveChanges",
	"radioButtonContainer": "app__EditContacts__radioButtonContainer",
	"radioButtonContent": "app__EditContacts__radioButtonContent"
};
export default ___CSS_LOADER_EXPORT___;
