// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__StandardizationComponent__popUp{background:#fff}.app__StandardizationComponent__okButton{margin-left:var(--GW-SPACING-3)}.app__StandardizationComponent__noExactMatchAddress{font-weight:400;margin:var(--gw-button-container-margin-top) 0}.app__StandardizationComponent__standardizeAddressActions{float:right;margin-top:var(--gw-button-container-margin-top)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/StandardizationComponent/StandardizationComponent.module.scss"],"names":[],"mappings":"AAAA,sCACI,eAAA,CAGJ,yCACI,+BAAA,CAGJ,oDAEI,eAAA,CADA,8CACA,CAGJ,0DAEI,WAAA,CADA,gDACA","sourcesContent":[".popUp{\n    background: white;\n}\n\n.okButton {\n    margin-left: var(--GW-SPACING-3);\n}\n\n.noExactMatchAddress {\n    margin: var(--gw-button-container-margin-top) 0;\n    font-weight: 400;\n}\n\n.standardizeAddressActions {\n    margin-top: var(--gw-button-container-margin-top);\n    float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popUp": "app__StandardizationComponent__popUp",
	"okButton": "app__StandardizationComponent__okButton",
	"noExactMatchAddress": "app__StandardizationComponent__noExactMatchAddress",
	"standardizeAddressActions": "app__StandardizationComponent__standardizeAddressActions"
};
export default ___CSS_LOADER_EXPORT___;
