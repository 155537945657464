// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__StartChangePage__startChangeTitle{padding:20px 0}.app__StartChangePage__toggleField{width:150px}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policychange-ea-react/pages/StartChange/StartChangePage.module.scss"],"names":[],"mappings":"AAAA,wCACI,cAAA,CAEJ,mCACI,WAAA","sourcesContent":[".startChangeTitle {\n    padding: 20px 0;\n}\n.toggleField {\n    width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"startChangeTitle": "app__StartChangePage__startChangeTitle",
	"toggleField": "app__StartChangePage__toggleField"
};
export default ___CSS_LOADER_EXPORT___;
