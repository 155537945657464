// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__E1PAcknowledgementComponent__e1pAcknowledgementComponent .app__E1PAcknowledgementComponent__disclosureInfo,.app__E1PAcknowledgementComponent__e1pAcknowledgementComponent .app__E1PAcknowledgementComponent__guidelinesInfo{align-items:flex-start;display:flex;position:relative;width:auto}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1PAcknowledgementComponent/E1PAcknowledgementComponent.module.scss"],"names":[],"mappings":"AACI,kOAII,sBAAA,CAHA,YAAA,CAEA,iBAAA,CADA,UAEA","sourcesContent":[".e1pAcknowledgementComponent {\n    .disclosureInfo, .guidelinesInfo {\n        display: flex;\n        width: auto;\n        position: relative;\n        align-items: flex-start;\n    } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"e1pAcknowledgementComponent": "app__E1PAcknowledgementComponent__e1pAcknowledgementComponent",
	"disclosureInfo": "app__E1PAcknowledgementComponent__disclosureInfo",
	"guidelinesInfo": "app__E1PAcknowledgementComponent__guidelinesInfo"
};
export default ___CSS_LOADER_EXPORT___;
