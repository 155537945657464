// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__Contacts__gwContactsTabContainer{padding:var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8)}.app__Contacts__gwSectionTitle{--gw-contacts-header-page-bottom-line:2px;border-bottom:var(--gw-contacts-header-page-bottom-line) solid var(--GW-BORDER-COLOR);padding-bottom:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/Policies/Contacts/Contacts.module.scss"],"names":[],"mappings":"AAAA,uCACE,mEAAA,CAGF,+BACE,yCAAA,CACA,qFAAA,CACA,kCAAA","sourcesContent":[".gwContactsTabContainer {\n  padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8);\n}\n\n.gwSectionTitle {\n  --gw-contacts-header-page-bottom-line: 2px;\n  border-bottom: var(--gw-contacts-header-page-bottom-line) solid var(--GW-BORDER-COLOR);\n  padding-bottom: var(--GW-SPACING-3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gwContactsTabContainer": "app__Contacts__gwContactsTabContainer",
	"gwSectionTitle": "app__Contacts__gwSectionTitle"
};
export default ___CSS_LOADER_EXPORT___;
