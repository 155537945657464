// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__AccountSearchComponent__accountSearchComponent .app__AccountSearchComponent__searchGrid{background-color:var(--GW-BACKGROUND-COLOR-ELEMENT-ACTIVE);border:var(--GW-BORDER-WIDTH-THIN) solid var(--GW-BORDER-COLOR-2);border-radius:var(--GW-SPACING-2);margin:var(--GW-SPACING-1) 0 var(--GW-SPACING-6) 0;padding:var(--GW-SPACING-5)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/AccountSearchComponent/AccountSearchComponent.module.scss"],"names":[],"mappings":"AACI,8FAII,0DAAA,CAHA,iEAAA,CAEA,iCAAA,CAEA,kDAAA,CAHA,2BAGA","sourcesContent":[".accountSearchComponent {\n    .searchGrid {\n        border: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-BORDER-COLOR-2);\n        padding: var(--GW-SPACING-5);\n        border-radius: var(--GW-SPACING-2);\n        background-color: var(--GW-BACKGROUND-COLOR-ELEMENT-ACTIVE);\n        margin: var(--GW-SPACING-1) 0 var(--GW-SPACING-6) 0;\n    }  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accountSearchComponent": "app__AccountSearchComponent__accountSearchComponent",
	"searchGrid": "app__AccountSearchComponent__searchGrid"
};
export default ___CSS_LOADER_EXPORT___;
