// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__RiskAnalysisPage__gwTabSetWrapper>div{--tabSet-bar-heading-hover-color:var(--gw-black);overflow:hidden}.app__RiskAnalysisPage__tabsContainerTopSpacing{margin-top:calc(var(--GW-SPACING-4)*3)}.app__RiskAnalysisPage__tabContainer{flex:1 1}.app__RiskAnalysisPage__componentPadding{flex:1 1;padding:0 var(--GW-SPACING-4) var(--GW-SPACING-6) var(--GW-SPACING-4)}.app__RiskAnalysisPage__uwIssueContainer{margin:var(--GW-SPACING-6) 0 var(--GW-SPACING-6) 0}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policychange-eh-react/pages/common/RiskAnalysis/RiskAnalysisPage.module.scss"],"names":[],"mappings":"AACE,4CACE,gDAAA,CACA,eAAA,CAIJ,gDACE,sCAAA,CAGF,qCACE,QAAA,CAGF,yCAEE,QAAA,CADA,qEACA,CAGF,yCACE,kDAAA","sourcesContent":[".gwTabSetWrapper {\n  & > div {\n    --tabSet-bar-heading-hover-color: var(--gw-black);\n    overflow: hidden;\n  }\n}\n\n.tabsContainerTopSpacing {\n  margin-top: calc(var(--GW-SPACING-4) * 3);\n}\n\n.tabContainer {\n  flex: 1;\n}\n\n.componentPadding {\n  padding: 0 var(--GW-SPACING-4) var(--GW-SPACING-6) var(--GW-SPACING-4);\n  flex: 1;\n}\n\n.uwIssueContainer {\n  margin: var(--GW-SPACING-6) 0px var(--GW-SPACING-6) 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gwTabSetWrapper": "app__RiskAnalysisPage__gwTabSetWrapper",
	"tabsContainerTopSpacing": "app__RiskAnalysisPage__tabsContainerTopSpacing",
	"tabContainer": "app__RiskAnalysisPage__tabContainer",
	"componentPadding": "app__RiskAnalysisPage__componentPadding",
	"uwIssueContainer": "app__RiskAnalysisPage__uwIssueContainer"
};
export default ___CSS_LOADER_EXPORT___;
