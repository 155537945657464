// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__EHPolicyTermComponent__policyTermPadding{padding-bottom:var(--GW-SPACING-2);padding-top:var(--GW-SPACING-3)}.app__EHPolicyTermComponent__iconLabel{font-size:var(--GW-FONT-SIZE-SM)}.app__EHPolicyTermComponent__radioControls{display:flex;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/EHPolicyTermComponent/EHPolicyTermComponent.module.scss"],"names":[],"mappings":"AAAA,+CAEI,kCAAA,CADA,+BACA,CAGJ,uCACI,gCAAA,CAGJ,2CACI,YAAA,CACA,6BAAA","sourcesContent":[".policyTermPadding {\n    padding-top: var(--GW-SPACING-3);\n    padding-bottom: var(--GW-SPACING-2);\n}\n\n.iconLabel {\n    font-size: var(--GW-FONT-SIZE-SM);\n  }\n  \n.radioControls {\n    display: flex;\n    justify-content: space-between;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"policyTermPadding": "app__EHPolicyTermComponent__policyTermPadding",
	"iconLabel": "app__EHPolicyTermComponent__iconLabel",
	"radioControls": "app__EHPolicyTermComponent__radioControls"
};
export default ___CSS_LOADER_EXPORT___;
