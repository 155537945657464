// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__Contacts__gwContactsTabContainer{padding:var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6)}.app__Contacts__gwTitleStyle{border-bottom:var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);padding:var(--GW-SPACING-3) 0}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/Accounts/Contacts/Contacts.module.scss"],"names":[],"mappings":"AAAA,uCACE,uFAAA,CAEF,6BAEE,iEAAA,CADA,6BACA","sourcesContent":[".gwContactsTabContainer {\n  padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);\n}\n.gwTitleStyle {\n  padding: var(--GW-SPACING-3) 0;\n  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gwContactsTabContainer": "app__Contacts__gwContactsTabContainer",
	"gwTitleStyle": "app__Contacts__gwTitleStyle"
};
export default ___CSS_LOADER_EXPORT___;
