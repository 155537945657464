// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__QuoteProposalComponent__toolTipIcon{font-size:var(--font-sm)!important;margin-left:var(--GW-SPACING-1);vertical-align:top!important}.app__QuoteProposalComponent__quoteProposal{font-size:var(--font-sm);font-weight:var(--font-weight-normal-bold);margin-left:var(--GW-SPACING-6)}.app__QuoteProposalComponent__quoteProposal>i{font-size:var(--font-sm)!important;margin-right:var(--GW-SPACING-1)!important}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/QuoteProposalComponent/QuoteProposalComponent.module.scss"],"names":[],"mappings":"AAAA,0CAEI,kCAAA,CADA,+BAAA,CAEA,4BAAA,CAGJ,4CAGI,wBAAA,CADA,0CAAA,CADA,+BAEA,CAGJ,8CAEI,kCAAA,CADA,0CACA","sourcesContent":[".toolTipIcon {\n    margin-left: var(--GW-SPACING-1);\n    font-size: var(--font-sm) !important;\n    vertical-align: top !important;\n}\n\n.quoteProposal {\n    margin-left: var(--GW-SPACING-6);\n    font-weight: var(--font-weight-normal-bold);\n    font-size: var(--font-sm);\n}\n\n.quoteProposal > i {\n    margin-right: var(--GW-SPACING-1) !important;\n    font-size: var(--font-sm) !important; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolTipIcon": "app__QuoteProposalComponent__toolTipIcon",
	"quoteProposal": "app__QuoteProposalComponent__quoteProposal"
};
export default ___CSS_LOADER_EXPORT___;
