// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ContactForAssistance__pageTitleStyle{padding-top:var(--GW-SPACING-4)}.app__ContactForAssistance__pageContainerStyle{padding-bottom:calc(var(--GW-SPACING-4)*2);padding-top:calc(var(--GW-SPACING-4)*4)}.app__ContactForAssistance__contactForAssistanceStyle{padding-bottom:calc(var(--GW-SPACING-4)*4);padding-top:calc(var(--GW-SPACING-4)*3)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/ContactForAssistance/ContactForAssistance.module.scss"],"names":[],"mappings":"AAAA,2CACI,+BAAA,CAGJ,+CAEI,0CAAA,CADA,uCACA,CAGJ,sDAEI,0CAAA,CADA,uCACA","sourcesContent":[".pageTitleStyle {\n    padding-top: var(--GW-SPACING-4) ;\n}\n\n.pageContainerStyle {\n    padding-top: calc(var(--GW-SPACING-4) * 4);\n    padding-bottom: calc(var(--GW-SPACING-4) * 2);\n}\n\n.contactForAssistanceStyle {\n    padding-top: calc(var(--GW-SPACING-4) * 3);\n    padding-bottom: calc(var(--GW-SPACING-4) * 4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageTitleStyle": "app__ContactForAssistance__pageTitleStyle",
	"pageContainerStyle": "app__ContactForAssistance__pageContainerStyle",
	"contactForAssistanceStyle": "app__ContactForAssistance__contactForAssistanceStyle"
};
export default ___CSS_LOADER_EXPORT___;
