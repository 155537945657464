import { defineMessages } from 'react-intl';

export default defineMessages({
    orderingCreditReportsMessage: {
        id: 'quoteandbind.CreditReportsComponent.Ordering Credit Reports...',
        defaultMessage: 'Ordering Credit Reports...'
    },
    reportStatus: {
        id: 'quoteandbind.CreditReportsComponent.Report Status',
        defaultMessage: 'Report Status'
    },
    creditScore: {
        id: 'quoteandbind.CreditReportsComponent.Credit Score',
        defaultMessage: 'Credit Score'
    },
    rateInstruction: {
        id: 'quoteandbind.CreditReportsComponent.Rate Instruction',
        defaultMessage: 'Rate Instruction'
    },
    creditReportType: {
        id: 'quoteandbind.CreditReportsComponent.Type',
        defaultMessage: 'Type'
    },
    creditReportClass: {
        id: 'quoteandbind.CreditReportsComponent.Class',
        defaultMessage: 'Class'
    },
    creditReportDate: {
        id: 'quoteandbind.CreditReportsComponent.Report Date',
        defaultMessage: 'Report Date'
    },
    creditInsuranceScore: {
        id: 'quoteandbind.CreditReportsComponent.Insurance Score Group',
        defaultMessage: 'Insurance Score Group'
    },
    pniForceOrderCredit: {
        id: 'quoteandbind.CreditReportsComponent.Force Order Primary Named Insured Credit',
        defaultMessage: 'Force Order Primary Named Insured Credit'
    },
    sniForceOrderCredit: {
        id: 'quoteandbind.CreditReportsComponent.Force Order Secondary Named Insured Credit',
        defaultMessage: 'Force Order Secondary Named Insured Credit'
    },
    calculate: {
        id: 'quoteandbind.CreditReportsComponent.Calculate',
        defaultMessage: 'Calculate'
    },
    addException: {
        id: 'quoteandbind.CreditReportsComponent.Add Exception',
        defaultMessage: 'Add Exception'
    },
    addFix: {
        id: 'quoteandbind.CreditReportsComponent.Add Fix',
        defaultMessage: 'Add Fix'
    },
    modifyCredit: {
        id: 'quoteandbind.CreditReportsComponent.Modify Credit',
        defaultMessage: 'Modify Credit'
    },
    overlappingExceptionTitle: {
        id: 'quoteandbind.CreditReportsComponent.Overlapping Exceptions',
        defaultMessage: 'Overlapping Exceptions'
    },
    overlappingExceptionMessage: {
        id: 'quoteandbind.CreditReportsComponent.Exception Dates are overlapping. Please review and correct the dates',
        defaultMessage: 'Exception Dates are overlapping. Please review and correct the dates'
    }
});
