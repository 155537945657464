// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__AccountsFoundComponent__smallPopUp{border-radius:0;width:675px}.app__AccountsFoundComponent__smallPopUp div[class=jut__ModalFooter__modalFooter]>div{justify-content:end!important}.app__AccountsFoundComponent__accountsFoundContainer{margin-top:32px;max-height:60vh;overflow-y:auto}.app__AccountsFoundComponent__messageStyle{font-family:var(--roboto-medium-font-family)!important}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/AccountsFoundComponent/AccountsFoundComponent.module.scss"],"names":[],"mappings":"AAAA,yCAEE,eAAA,CADA,WACA,CACA,sFACE,6BAAA,CAIJ,qDACE,eAAA,CACA,eAAA,CACA,eAAA,CAGF,2CACE,sDAAA","sourcesContent":[".smallPopUp {\n  width: 675px;\n  border-radius: 0px;\n  div[class='jut__ModalFooter__modalFooter'] > div {\n    justify-content: end !important;\n  }\n}\n\n.accountsFoundContainer {\n  margin-top: 32px;\n  max-height: 60vh;\n  overflow-y: auto;\n}\n\n.messageStyle {\n  font-family: var(--roboto-medium-font-family) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smallPopUp": "app__AccountsFoundComponent__smallPopUp",
	"accountsFoundContainer": "app__AccountsFoundComponent__accountsFoundContainer",
	"messageStyle": "app__AccountsFoundComponent__messageStyle"
};
export default ___CSS_LOADER_EXPORT___;
