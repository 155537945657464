// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__EHScheduleItemDetailsModal__buttonContainer{display:flex;justify-content:flex-end;padding-top:var(--GW-SPACING-4)}.app__EHScheduleItemDetailsModal__cancelButton{margin-right:var(--GW-SPACING-6)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/EHScheduleItemsComponent/EHScheduleItemDetailsModal.module.scss"],"names":[],"mappings":"AACA,kDACE,YAAA,CACA,wBAAA,CACA,+BAAA,CAEF,+CACE,gCAAA","sourcesContent":["/* @import \"~e1p-common-styles\";*/\n.buttonContainer {\n  display: flex;\n  justify-content: flex-end;\n  padding-top: var(--GW-SPACING-4);\n}\n.cancelButton {\n  margin-right: var(--GW-SPACING-6);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": "app__EHScheduleItemDetailsModal__buttonContainer",
	"cancelButton": "app__EHScheduleItemDetailsModal__cancelButton"
};
export default ___CSS_LOADER_EXPORT___;
