// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__StartReinstatementPage__reinstateItem{margin-bottom:8px;width:50%}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-reinstatement-common-react/pages/StartReinstatement/StartReinstatementPage.module.scss"],"names":[],"mappings":"AAAA,4CACI,iBAAA,CACA,SAAA","sourcesContent":[".reinstateItem{\n    margin-bottom: 8px;\n    width: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reinstateItem": "app__StartReinstatementPage__reinstateItem"
};
export default ___CSS_LOADER_EXPORT___;
