// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__EALossReportComponent__usedInRatingField{width:35px}.app__EALossReportComponent__lossAmountField div[class*=jut__CurrencyField__inputContainer]{height:var(--GW-LINE-HEIGHT-H2)!important}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/EALossReportComponent/EALossReportComponent.module.scss"],"names":[],"mappings":"AAAA,+CACE,UAAA,CAIA,4FACE,yCAAA","sourcesContent":[".usedInRatingField{\n  width: 35px\n}\n\n.lossAmountField {\n  div[class*=\"jut__CurrencyField__inputContainer\"] {\n    height: var(--GW-LINE-HEIGHT-H2) !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"usedInRatingField": "app__EALossReportComponent__usedInRatingField",
	"lossAmountField": "app__EALossReportComponent__lossAmountField"
};
export default ___CSS_LOADER_EXPORT___;
