// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__PackageQualifyingEndorsementsComponent__qualifyingCoveragesDescription{color:var(--msa-grey-500)!important;margin-bottom:var(--GW-SPACING-5)!important;margin-top:var(--GW-SPACING-5)!important}.app__PackageQualifyingEndorsementsComponent__closeButton{text-transform:capitalize}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/PackageQualifyingEndorsementsComponent/PackageQualifyingEndorsementsComponent.module.scss"],"names":[],"mappings":"AAEA,6EACI,mCAAA,CAEA,2CAAA,CADA,wCACA,CAGJ,0DACI,yBAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n\n.qualifyingCoveragesDescription{\n    color: var(--msa-grey-500) !important;\n    margin-top: var(--GW-SPACING-5) !important;\n    margin-bottom: var(--GW-SPACING-5) !important;\n}\n\n.closeButton{\n    text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qualifyingCoveragesDescription": "app__PackageQualifyingEndorsementsComponent__qualifyingCoveragesDescription",
	"closeButton": "app__PackageQualifyingEndorsementsComponent__closeButton"
};
export default ___CSS_LOADER_EXPORT___;
