// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__Summary__cancellationRefundAmount{--field-component-control-readonly-font-weight:var(--GW-FONT-WEIGHT-REGULAR);--field-component-control-readonly-font-size:var(--GW-FONT-SIZE-H3)}.app__Summary__notificationGridStyle{padding-bottom:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/Cancellation/Summary/Summary.module.scss"],"names":[],"mappings":"AAAA,wCACC,4EAAA,CACA,mEAAA,CAGD,qCACC,kCAAA","sourcesContent":[".cancellationRefundAmount {\n\t--field-component-control-readonly-font-weight: var(--GW-FONT-WEIGHT-REGULAR);\n\t--field-component-control-readonly-font-size: var(--GW-FONT-SIZE-H3);\n}\n\n.notificationGridStyle {\n\tpadding-bottom: var(--GW-SPACING-3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancellationRefundAmount": "app__Summary__cancellationRefundAmount",
	"notificationGridStyle": "app__Summary__notificationGridStyle"
};
export default ___CSS_LOADER_EXPORT___;
