// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__DefensiveDriverComponent__popUp{background:#fff}.app__DefensiveDriverComponent__okButton{background:var(--msa-primary-blue);color:none;font-weight:var(--font-weight-bold)}.app__DefensiveDriverComponent__okButton,.app__DefensiveDriverComponent__okButton:active:focus,.app__DefensiveDriverComponent__okButton:focus{border:none}.app__DefensiveDriverComponent__accountsCancelButton{background:none;border:none;color:var(--msa-primary-blue);font-weight:var(--font-weight-bold)}.app__DefensiveDriverComponent__accountsCancelButton:active:focus,.app__DefensiveDriverComponent__accountsCancelButton:focus{border:none}.app__DefensiveDriverComponent__viewOnlyField{padding-bottom:var(--GW-SPACING-2);padding-top:var(--GW-SPACING-2)}.app__DefensiveDriverComponent__commonMargin{margin-top:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/DefensiveDriverComponent/DefensiveDriverComponent.module.scss"],"names":[],"mappings":"AAAA,sCACI,eAAA,CAGJ,yCACI,kCAAA,CAGA,UAAA,CAFA,mCAEA,CAMA,8IACI,WAAA,CAIR,qDACI,eAAA,CAEA,WAAA,CACA,6BAAA,CAFA,mCAEA,CAMA,6HACI,WAAA,CAIR,8CAEI,kCAAA,CADA,+BACA,CAGJ,6CACI,8BAAA","sourcesContent":[".popUp{\n    background: white;\n}\n\n.okButton {\n    background: var(--msa-primary-blue);\n    font-weight: var(--font-weight-bold);\n    border: none;\n    color: none;\n\n    &:active:focus {\n        border: none;\n    }\n\n    &:focus {\n        border: none;\n    }\n}\n\n.accountsCancelButton {\n    background: none;\n    font-weight: var(--font-weight-bold);\n    border: none;\n    color:  var(--msa-primary-blue);\n\n    &:active:focus {\n        border: none;\n    }\n\n    &:focus {\n        border: none;\n    }\n}\n\n.viewOnlyField {\n    padding-top: var(--GW-SPACING-2);\n    padding-bottom: var(--GW-SPACING-2)\n  }\n\n.commonMargin {\n    margin-top: var(--GW-SPACING-3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popUp": "app__DefensiveDriverComponent__popUp",
	"okButton": "app__DefensiveDriverComponent__okButton",
	"accountsCancelButton": "app__DefensiveDriverComponent__accountsCancelButton",
	"viewOnlyField": "app__DefensiveDriverComponent__viewOnlyField",
	"commonMargin": "app__DefensiveDriverComponent__commonMargin"
};
export default ___CSS_LOADER_EXPORT___;
