// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__E1PCreditReportModalComponent__smallPopUp{border-radius:0;width:calc(var(--GW-SPACING-5)*40)}.app__E1PCreditReportModalComponent__smallPopUp div[class*=digitalFieldComponentAliginLeft]{grid-template-columns:1.75fr 2.5fr!important}.app__E1PCreditReportModalComponent__smallPopUp hr{margin:var(--GW-SPACING-6) 0!important}.app__E1PCreditReportModalComponent__creditModalContainer{margin:var(--GW-SPACING-8)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1PCreditReportModalComponent/E1PCreditReportModalComponent.module.scss"],"names":[],"mappings":"AAAA,gDAEE,eAAA,CADA,kCACA,CACA,4FACE,4CAAA,CAEF,mDACE,sCAAA,CAIJ,0DACE,0BAAA","sourcesContent":[".smallPopUp {\n  width: calc(var(--GW-SPACING-5) * 40);\n  border-radius: 0px;\n  div[class*=\"digitalFieldComponentAliginLeft\"] {\n    grid-template-columns: 1.75fr 2.5fr !important;\n  }\n  hr {\n    margin: var(--GW-SPACING-6) 0px !important;\n  }\n}\n\n.creditModalContainer {\n  margin: var(--GW-SPACING-8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smallPopUp": "app__E1PCreditReportModalComponent__smallPopUp",
	"creditModalContainer": "app__E1PCreditReportModalComponent__creditModalContainer"
};
export default ___CSS_LOADER_EXPORT___;
