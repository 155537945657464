// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__SideBySideActionBarComponent__toolbarContainer{margin-top:calc(var(--GW-SPACING-8)*2.5)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/SideBySideActionBarComponent/SideBySideActionBarComponent.module.scss"],"names":[],"mappings":"AAAA,qDACE,wCAAA","sourcesContent":[".toolbarContainer {\n  margin-top: calc(var(--GW-SPACING-8) * 2.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarContainer": "app__SideBySideActionBarComponent__toolbarContainer"
};
export default ___CSS_LOADER_EXPORT___;
