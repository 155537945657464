// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__EHPropertyInspectionComponent__labelItem{margin-bottom:0!important}.app__EHPropertyInspectionComponent__dataItem{font-weight:700}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/EHPropertyInspectionComponent/EHPropertyInspectionComponent.module.scss"],"names":[],"mappings":"AAAA,+CACE,yBAAA,CAGF,8CACE,eAAA","sourcesContent":[".labelItem {\n  margin-bottom: 0px !important;\n}\n\n.dataItem{\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelItem": "app__EHPropertyInspectionComponent__labelItem",
	"dataItem": "app__EHPropertyInspectionComponent__dataItem"
};
export default ___CSS_LOADER_EXPORT___;
