// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ScheduleItemsComponent__scheduleItemsTable{--gw-schedule-item-table-spacing:var(--GW-SPACING-1);margin-top:var(--gw-schedule-item-table-spacing)}.app__ScheduleItemsComponent__scheduleItemsTable th{text-align:left}div[class*=digitalFieldComponentAliginLeft]{grid-template-columns:1.5fr 2.5fr!important}td.app__ScheduleItemsComponent__gwTableCell{--table-cell-border-width:1px;--table-border-color:var(--gw-grey-light-30-color);border:var(--gw-quote-table-border);grid-template-columns:1.5fr 2.5fr;vertical-align:top}.app__ScheduleItemsComponent__rowMargin{margin-bottom:calc(var(--GW-SPACING-1)*.5)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/EHScheduleItemsComponent/ScheduleItemsComponent.module.scss"],"names":[],"mappings":"AAAA,iDACE,oDAAA,CAEA,gDAAA,CAEA,oDACE,eAAA,CAIJ,4CACE,2CAAA,CAGF,4CACE,6BAAA,CACA,kDAAA,CAGA,mCAAA,CAFA,iCAAA,CACA,kBACA,CAGF,wCACE,0CAAA","sourcesContent":[".scheduleItemsTable {\n  --gw-schedule-item-table-spacing: var(--GW-SPACING-1);\n\n  margin-top: var(--gw-schedule-item-table-spacing);\n\n  & th {\n    text-align: left;\n  }\n}\n\ndiv[class*=\"digitalFieldComponentAliginLeft\"] {\n  grid-template-columns: 1.5fr 2.5fr !important;\n}\n\ntd.gwTableCell {\n  --table-cell-border-width: 1px;\n  --table-border-color: var(--gw-grey-light-30-color);\n  grid-template-columns: 1.5fr 2.5fr;\n  vertical-align: top;\n  border: var(--gw-quote-table-border);\n}\n\n.rowMargin {\n  margin-bottom: calc(var(--GW-SPACING-1) * 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scheduleItemsTable": "app__ScheduleItemsComponent__scheduleItemsTable",
	"gwTableCell": "app__ScheduleItemsComponent__gwTableCell",
	"rowMargin": "app__ScheduleItemsComponent__rowMargin"
};
export default ___CSS_LOADER_EXPORT___;
