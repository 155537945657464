// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__AdditionalInfoDriver__sectionContainers{border:1px solid var(--msa-grid-border-grey);margin-bottom:var(--GW-SPACING-5);padding:var(--GW-SPACING-3);width:52%}.app__AdditionalInfoDriver__textLinks{color:var(--msa-primary-blue);text-align:left}.app__AdditionalInfoDriver__popoverContainer{overflow-y:auto}.app__AdditionalInfoDriver__radioField{display:inline;vertical-align:top}.app__AdditionalInfoDriver__driverLicenseWithLabel{display:flex}.app__AdditionalInfoDriver__divider{border-color:var(--msa-divider-grey)!important;margin:var(--GW-LAYOUT-4) 0!important}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-quoteandbind-ea-react/components/AdditionalInfoDriver/AdditionalInfoDriver.module.scss"],"names":[],"mappings":"AAAA,8CACI,4CAAA,CACA,iCAAA,CACA,2BAAA,CACA,SAAA,CAGF,sCACE,6BAAA,CACA,eAAA,CAGF,6CACE,eAAA,CAGF,uCACE,cAAA,CACA,kBAAA,CAGF,mDACE,YAAA,CAIF,oCACE,8CAAA,CACA,qCAAA","sourcesContent":[".sectionContainers {\n    border: solid 1px var(--msa-grid-border-grey);\n    margin-bottom: var(--GW-SPACING-5);\n    padding:var(--GW-SPACING-3);\n    width : 52%;\n  }\n  \n  .textLinks {\n    color:  var(--msa-primary-blue);\n    text-align: left;\n  }\n  \n  .popoverContainer {\n    overflow-y: auto;\n  }\n  \n  .radioField {\n    display: inline;\n    vertical-align: top;\n  }\n  \n  .driverLicenseWithLabel {\n    display: flex;\n    /* justify-content: space-between;*/\n  }\n\n  .divider {\n    border-color: var(--msa-divider-grey) !important;\n    margin: var(--GW-LAYOUT-4) 0 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionContainers": "app__AdditionalInfoDriver__sectionContainers",
	"textLinks": "app__AdditionalInfoDriver__textLinks",
	"popoverContainer": "app__AdditionalInfoDriver__popoverContainer",
	"radioField": "app__AdditionalInfoDriver__radioField",
	"driverLicenseWithLabel": "app__AdditionalInfoDriver__driverLicenseWithLabel",
	"divider": "app__AdditionalInfoDriver__divider"
};
export default ___CSS_LOADER_EXPORT___;
