// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__EAViolationReportComponent__usedInRatingField{width:35px}.app__EAViolationReportComponent__violationGridWithPrayerForJudgement>div{grid-template-columns:1.5fr 1.5fr 1.5fr 1.5fr 1fr}.app__EAViolationReportComponent__violationGridWithoutPrayerForJudgement>div{grid-template-columns:1.5fr 1.5fr 1.5fr 1fr}.app__EAViolationReportComponent__prayerForJudgementIndicator{margin:auto;width:2rem}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/EAViolationReportComponent/EAViolationReportComponent.module.scss"],"names":[],"mappings":"AAAA,oDACE,UAAA,CAGF,0EACE,iDAAA,CAGF,6EACE,2CAAA,CAGF,8DACE,WAAA,CACA,UAAA","sourcesContent":[".usedInRatingField{\n  width: 35px\n}\n\n.violationGridWithPrayerForJudgement > div {\n  grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr 1fr;\n}\n\n.violationGridWithoutPrayerForJudgement > div {\n  grid-template-columns: 1.5fr 1.5fr 1.5fr 1fr;\n}\n\n.prayerForJudgementIndicator {\n  margin: auto;\n  width: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"usedInRatingField": "app__EAViolationReportComponent__usedInRatingField",
	"violationGridWithPrayerForJudgement": "app__EAViolationReportComponent__violationGridWithPrayerForJudgement",
	"violationGridWithoutPrayerForJudgement": "app__EAViolationReportComponent__violationGridWithoutPrayerForJudgement",
	"prayerForJudgementIndicator": "app__EAViolationReportComponent__prayerForJudgementIndicator"
};
export default ___CSS_LOADER_EXPORT___;
