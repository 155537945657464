// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__E1PWindstormMitigationDiscountModal__windstormMitigationDiscountModalContainer{display:flex;flex-direction:column;row-gap:var(--GW-SPACING-5)}.app__E1PWindstormMitigationDiscountModal__toggleField{width:150px}.app__E1PWindstormMitigationDiscountModal__designation div[class*=\"tippy-box jut__Tooltip__tooltip\"]{max-width:calc(var(--GW-SPACING-6)*25)!important}.app__E1PWindstormMitigationDiscountModal__designationExpirationDate{width:250px}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1PWindstormMitigationDiscountModal/E1PWindstormMitigationDiscountModal.module.scss"],"names":[],"mappings":"AAAA,qFACE,YAAA,CACA,qBAAA,CACA,2BAAA,CAGF,uDACE,WAAA,CAIA,qGACE,gDAAA,CAIJ,qEACE,WAAA","sourcesContent":[".windstormMitigationDiscountModalContainer {\n  display: flex;\n  flex-direction: column;\n  row-gap: var(--GW-SPACING-5);\n}\n\n.toggleField {\n  width: 150px;\n}\n\n.designation {\n  div[class*='tippy-box jut__Tooltip__tooltip'] {\n    max-width: calc(var(--GW-SPACING-6) * 25) !important;\n  }\n}\n\n.designationExpirationDate {\n  width: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"windstormMitigationDiscountModalContainer": "app__E1PWindstormMitigationDiscountModal__windstormMitigationDiscountModalContainer",
	"toggleField": "app__E1PWindstormMitigationDiscountModal__toggleField",
	"designation": "app__E1PWindstormMitigationDiscountModal__designation",
	"designationExpirationDate": "app__E1PWindstormMitigationDiscountModal__designationExpirationDate"
};
export default ___CSS_LOADER_EXPORT___;
