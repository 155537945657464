// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__E1PRenewalNonRenewComponent__nonRenewModalStyling{padding-left:10px;padding-right:10px;width:600px!important}.app__E1PRenewalNonRenewComponent__nonRenewModalStyling div[class=jut__ModalFooter__modalFooter]>div{justify-content:right!important}.app__E1PRenewalNonRenewComponent__nonRenewModalStyling div[class=jut__ModalHeader__modalHeader]>div[class=jut__ModalHeader__modalStatus]{display:none!important}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1PRenewalNonRenewComponent/E1PRenewalNonRenewComponent.module.scss"],"names":[],"mappings":"AAAA,wDAEE,iBAAA,CACA,kBAAA,CAFA,qBAEA,CACA,qGACE,+BAAA,CAIA,0IACE,sBAAA","sourcesContent":[".nonRenewModalStyling {\n  width: 600px !important ;\n  padding-left: 10px;\n  padding-right: 10px;\n  div[class='jut__ModalFooter__modalFooter'] > div {\n    justify-content: right !important;\n  }\n\n  div[class='jut__ModalHeader__modalHeader'] > {\n    div[class='jut__ModalHeader__modalStatus'] {\n      display: none !important;\n  }\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nonRenewModalStyling": "app__E1PRenewalNonRenewComponent__nonRenewModalStyling"
};
export default ___CSS_LOADER_EXPORT___;
