// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__RemoteClick2SignModal__partialScreen{width:45%}.app__RemoteClick2SignModal__gridCell{padding:10px}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1PSignatureComponent/RemoteClick2SignModal/RemoteClick2SignModal.module.scss"],"names":[],"mappings":"AAAA,2CACI,SAAA,CAGJ,sCACI,YAAA","sourcesContent":[".partialScreen {\n    width: 45%;\n}\n\n.gridCell {\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partialScreen": "app__RemoteClick2SignModal__partialScreen",
	"gridCell": "app__RemoteClick2SignModal__gridCell"
};
export default ___CSS_LOADER_EXPORT___;
