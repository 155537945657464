// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__E1PWindstormMitigationDiscountComponent__discountToggle{width:150px;--gw-toggle-active-background:linear-gradient(to top,var(--msa-accent-orange) 4px,var(--gw-white) 5px)}.app__E1PWindstormMitigationDiscountComponent__windstormMitigationDiscountActionIconButton{cursor:pointer;display:contents;z-index:1}.app__E1PWindstormMitigationDiscountComponent__windstormMitigationDiscountActionIcon{color:var(--msa-cyan-dark)}.app__E1PWindstormMitigationDiscountComponent__nowrap label{white-space:nowrap}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1PWindstormMitigationDiscountComponent/E1PWindstormMitigationDiscountComponent.module.scss"],"names":[],"mappings":"AAAA,8DACI,WAAA,CACA,sGAAA,CAOF,2FACE,cAAA,CAEA,gBAAA,CADA,SACA,CAGF,qFACE,0BAAA,CAIA,4DACE,kBAAA","sourcesContent":[".discountToggle {\n    width: 150px;\n    --gw-toggle-active-background: linear-gradient(\n      to top,\n      var(--msa-accent-orange) 4px,\n      var(--gw-white) 5px\n    );\n  }\n  \n  .windstormMitigationDiscountActionIconButton {\n    cursor: pointer;\n    z-index: 1;\n    display: contents;\n  }\n  \n  .windstormMitigationDiscountActionIcon {\n    color: var(--msa-cyan-dark);\n  }\n  \n  .nowrap {\n    label {\n      white-space: nowrap;\n    }\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"discountToggle": "app__E1PWindstormMitigationDiscountComponent__discountToggle",
	"windstormMitigationDiscountActionIconButton": "app__E1PWindstormMitigationDiscountComponent__windstormMitigationDiscountActionIconButton",
	"windstormMitigationDiscountActionIcon": "app__E1PWindstormMitigationDiscountComponent__windstormMitigationDiscountActionIcon",
	"nowrap": "app__E1PWindstormMitigationDiscountComponent__nowrap"
};
export default ___CSS_LOADER_EXPORT___;
