// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__InsuredContactComponent__commonStyle{margin-bottom:calc(var(--GW-SPACING-4)*4);margin-top:calc(var(--GW-SPACING-4)*4)}.app__InsuredContactComponent__flexRow{width:700px}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/InsuredContactComponent/InsuredContactComponent.module.scss"],"names":[],"mappings":"AAAA,2CACE,yCAAA,CACA,sCAAA,CAGF,uCACE,WAAA","sourcesContent":[".commonStyle {\n  margin-bottom: calc(var(--GW-SPACING-4) * 4);\n  margin-top: calc(var(--GW-SPACING-4) * 4);\n}\n\n.flexRow {\n  width: 700px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commonStyle": "app__InsuredContactComponent__commonStyle",
	"flexRow": "app__InsuredContactComponent__flexRow"
};
export default ___CSS_LOADER_EXPORT___;
