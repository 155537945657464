// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__PolicyCancelWizard__hoPolicyChangeWizardContainer div[class*=wizardMain]{--gw-wizard-page-main-width:100%}.app__PolicyCancelWizard__gwInfolabelLabelStyle{--info-label-color:var(--gw-grey-light-6);--info-label-font-size-m:var(--info-label-font-size);--info-label-bg-color-neutral:none;--fieldLabel-text-align-left:left;--info-label-padding-x-m:none}.app__PolicyCancelWizard__gridPaddingLeft{grid-template-columns:0fr 3fr;padding-bottom:var(--grid-padding-bottom)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policycancel-common-react/PolicyCancelWizard.module.scss"],"names":[],"mappings":"AACI,+EACI,gCAAA,CAIR,gDACI,yCAAA,CACA,oDAAA,CACA,kCAAA,CACA,iCAAA,CACA,6BAAA,CAIJ,0CAEI,6BAAA,CADA,yCACA","sourcesContent":[".hoPolicyChangeWizardContainer {\n    div[class*='wizardMain'] {\n        --gw-wizard-page-main-width: 100%;\n    }\n}\n\n.gwInfolabelLabelStyle {\n    --info-label-color: var(--gw-grey-light-6);\n    --info-label-font-size-m: var(--info-label-font-size);\n    --info-label-bg-color-neutral: none;\n    --fieldLabel-text-align-left: left;\n    --info-label-padding-x-m: none;\n\n}\n\n.gridPaddingLeft{\n    padding-bottom: var(--grid-padding-bottom);\n    grid-template-columns: 0fr 3fr;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hoPolicyChangeWizardContainer": "app__PolicyCancelWizard__hoPolicyChangeWizardContainer",
	"gwInfolabelLabelStyle": "app__PolicyCancelWizard__gwInfolabelLabelStyle",
	"gridPaddingLeft": "app__PolicyCancelWizard__gridPaddingLeft"
};
export default ___CSS_LOADER_EXPORT___;
