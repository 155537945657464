// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ConfirmationPage__backToPolicyButton{margin-top:20px}.app__ConfirmationPage__confirmationMessage{display:flex;flex-direction:row;font-size:var(--font-xl);font-weight:var(--font-weight-normal-bold);margin-bottom:20px;position:relative;width:auto}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policychange-eh-react/pages/Confirmation/ConfirmationPage.module.scss"],"names":[],"mappings":"AAAA,2CACE,eAAA,CAEF,4CACE,YAAA,CACA,kBAAA,CAIA,wBAAA,CADA,0CAAA,CAEA,kBAAA,CAHA,iBAAA,CADA,UAIA","sourcesContent":[".backToPolicyButton {\n  margin-top: 20px;\n}\n.confirmationMessage {\n  display: flex;\n  flex-direction: row;\n  width: auto;\n  position: relative;\n  font-weight: var(--font-weight-normal-bold);\n  font-size: var(--font-xl);\n  margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backToPolicyButton": "app__ConfirmationPage__backToPolicyButton",
	"confirmationMessage": "app__ConfirmationPage__confirmationMessage"
};
export default ___CSS_LOADER_EXPORT___;
