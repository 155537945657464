// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__EADriverPrefill__eaDriverPrefillContainer .app__EADriverPrefill__width-800{width:var(--GW-TABLE-DIV-WIDTH)}.app__EADriverPrefill__eaDriverPrefillContainer .app__EADriverPrefill__eaDriverPreFillGrid>div{border:var(--GW-BORDER-WIDTH-THIN) solid var(--msa-grey-200);padding:var(--GW-SPACING-4)}.app__EADriverPrefill__eaDriverPrefillContainer .app__EADriverPrefill__eaDriverPreFillGrid>div:nth-child(2n){background-color:var(--msa-grey-200)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-quoteandbind-ea-react/pages/Drivers/EADriverPrefill/EADriverPrefill.module.scss"],"names":[],"mappings":"AACE,iFACE,+BAAA,CAGF,+FACE,4DAAA,CACA,2BAAA,CAGF,6GACE,oCAAA","sourcesContent":[".eaDriverPrefillContainer {\n  .width-800 {\n    width: var(--GW-TABLE-DIV-WIDTH);\n  }\n\n  .eaDriverPreFillGrid > div {\n    border: var(--GW-BORDER-WIDTH-THIN) solid var(--msa-grey-200);\n    padding: var(--GW-SPACING-4);\n  }\n  \n  .eaDriverPreFillGrid > div:nth-child(even) {\n    background-color: var(--msa-grey-200);\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eaDriverPrefillContainer": "app__EADriverPrefill__eaDriverPrefillContainer",
	"width-800": "app__EADriverPrefill__width-800",
	"eaDriverPreFillGrid": "app__EADriverPrefill__eaDriverPreFillGrid"
};
export default ___CSS_LOADER_EXPORT___;
