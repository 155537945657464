// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--gw-modal-title-padding-top:20px;--gw-modal-title-padding-bottom:25px;--gw-modal-width:1200px;--gw-button-container-margin-top:10px;--gw-button-container-margin-bottom:15px;--gw-cancel-button-margin-right:10px;--gw-cancel-button-margin-left:10px}.app__PersonalInjuryProtectionComponent__buttonContainer{display:flex;justify-content:flex-end;margin-bottom:15px;margin-bottom:var(--gw-button-container-margin-bottom)}.app__PersonalInjuryProtectionComponent__popUp{max-width:900px}.app__PersonalInjuryProtectionComponent__closeButton{text-transform:uppercase}.app__PersonalInjuryProtectionComponent__differenceContainer div[class*=rt-td]{display:block!important}.app__PersonalInjuryProtectionComponent__differenceContainer div[class*=rt-td] span{white-space:normal!important}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/PersonalInjuryProtectionComponent/PersonalInjuryProtectionComponent.module.scss"],"names":[],"mappings":"AAAA,MACE,iCAAA,CACA,oCAAA,CACA,uBAAA,CACA,qCAAA,CACA,wCAAA,CACA,oCAAA,CACA,mCAAA,CAGF,yDACE,YAAA,CACA,wBAAA,CACA,kBAAA,CAAA,sDAAA,CAGF,+CACE,eAAA,CAGF,qDACE,wBAAA,CAGA,+EACE,uBAAA,CACA,oFACE,4BAAA","sourcesContent":[":root {\n  --gw-modal-title-padding-top: 20px;\n  --gw-modal-title-padding-bottom: 25px;\n  --gw-modal-width: 1200px;\n  --gw-button-container-margin-top: 10px;\n  --gw-button-container-margin-bottom: 15px;\n  --gw-cancel-button-margin-right: 10px;\n  --gw-cancel-button-margin-left: 10px;\n}\n\n.buttonContainer {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: var(--gw-button-container-margin-bottom);\n}\n\n.popUp {\n  max-width: 900px;\n}\n\n.closeButton {\n  text-transform: uppercase;\n}\n.differenceContainer {\n  div[class*=\"rt-td\"] {\n    display: block !important;\n    span {\n      white-space: normal !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": "app__PersonalInjuryProtectionComponent__buttonContainer",
	"popUp": "app__PersonalInjuryProtectionComponent__popUp",
	"closeButton": "app__PersonalInjuryProtectionComponent__closeButton",
	"differenceContainer": "app__PersonalInjuryProtectionComponent__differenceContainer"
};
export default ___CSS_LOADER_EXPORT___;
