// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__E1PActivityComponent__modalDialog{background-color:#fff;overflow-y:auto}.app__E1PActivityComponent__assignedToContainer{width:60%}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1PActivityComponent/E1PActivityComponent.module.scss"],"names":[],"mappings":"AAAA,wCAEE,qBAAA,CADA,eACA,CAEF,gDACE,SAAA","sourcesContent":[".modalDialog {\n  overflow-y: auto;\n  background-color: white;\n}\n.assignedToContainer {\n  width: 60%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalDialog": "app__E1PActivityComponent__modalDialog",
	"assignedToContainer": "app__E1PActivityComponent__assignedToContainer"
};
export default ___CSS_LOADER_EXPORT___;
