// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__EASingleUWIssueComponent__uwIssuesContainer{background-color:var(--GW-COLOR-BACKGROUND-1-HOVER);border:thin;margin-left:var(--GW-SPACING-5);padding:var(--GW-SPACING-5)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/EASingleUWIssueComponent/EASingleUWIssueComponent.module.scss"],"names":[],"mappings":"AAAA,kDACE,mDAAA,CACA,WAAA,CAEA,+BAAA,CADA,2BACA","sourcesContent":[".uwIssuesContainer{\n  background-color: var(--GW-COLOR-BACKGROUND-1-HOVER);\n  border: thin;\n  padding: var(--GW-SPACING-5);\n  margin-left: var(--GW-SPACING-5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uwIssuesContainer": "app__EASingleUWIssueComponent__uwIssuesContainer"
};
export default ___CSS_LOADER_EXPORT___;
