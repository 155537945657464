// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__E1PLicenseComponent__viewOnlyDriverLicense{padding-bottom:var(--GW-SPACING-2);padding-top:var(--GW-SPACING-2)}.app__E1PLicenseComponent__viewOnlyField{align-items:center;display:flex;height:var(--GW-LINE-HEIGHT-H2)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1PLicenseComponent/E1PLicenseComponent.module.scss"],"names":[],"mappings":"AAAE,iDAEE,kCAAA,CADA,+BACA,CAGF,yCAGE,kBAAA,CADA,YAAA,CADA,+BAEA","sourcesContent":["  .viewOnlyDriverLicense {\n    padding-top: var(--GW-SPACING-2);\n    padding-bottom: var(--GW-SPACING-2)\n  }\n\n  .viewOnlyField {\n    height: var(--GW-LINE-HEIGHT-H2);\n    display: flex;\n    align-items: center;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewOnlyDriverLicense": "app__E1PLicenseComponent__viewOnlyDriverLicense",
	"viewOnlyField": "app__E1PLicenseComponent__viewOnlyField"
};
export default ___CSS_LOADER_EXPORT___;
