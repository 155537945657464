// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__EUPolicyChangeWizard__scanDriverLicenseBarcodeTitle{--gw-scan-driver-license-barcode-space:5px;font-weight:var(--font-weight-normal-bold);margin-right:var(--gw-scan-driver-license-barcode-space)}.app__EUPolicyChangeWizard__labelItem{border:.25px solid gray;border-radius:10px;font-size:14px;height:61px;padding:2.5px}.app__EUPolicyChangeWizard__dropdownSelect{width:150px}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policychange-eu-react/EUPolicyChangeWizard.module.scss"],"names":[],"mappings":"AAAA,0DACI,0CAAA,CACA,0CAAA,CACA,wDAAA,CAGJ,sCAII,uBAAA,CACA,kBAAA,CAFA,cAAA,CAFA,WAAA,CACA,aAGA,CAGJ,2CACI,WAAA","sourcesContent":[".scanDriverLicenseBarcodeTitle {\n    --gw-scan-driver-license-barcode-space: 5px;\n    font-weight: var(--font-weight-normal-bold);\n    margin-right: var(--gw-scan-driver-license-barcode-space);\n}\n\n.labelItem {\n    height: 61px; \n    padding: 2.5px; \n    font-size: 14px;\n    border: .25px solid grey; \n    border-radius: 10px;\n}\n\n.dropdownSelect {\n    width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scanDriverLicenseBarcodeTitle": "app__EUPolicyChangeWizard__scanDriverLicenseBarcodeTitle",
	"labelItem": "app__EUPolicyChangeWizard__labelItem",
	"dropdownSelect": "app__EUPolicyChangeWizard__dropdownSelect"
};
export default ___CSS_LOADER_EXPORT___;
