// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__UWIssueDetailPopup__longDescription{font-weight:400!important;margin-bottom:var(--GW-SPACING-4)}.app__UWIssueDetailPopup__modalWidth{width:var(--gw-modal-width)}.app__UWIssueDetailPopup__modalTitle{font-size:var(--font-xxl);font-weight:var(--font-weight-normal-bold);padding-bottom:var(--gw-modal-title-padding-bottom);padding-top:var(--gw-modal-title-padding-top)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/UWIssueDetailPopup/UWIssueDetailPopup.module.scss"],"names":[],"mappings":"AAAA,0CAEE,yBAAA,CADA,iCACA,CAGF,qCACE,2BAAA,CAGF,qCAEE,yBAAA,CADA,0CAAA,CAGA,mDAAA,CADA,6CACA","sourcesContent":[".longDescription {\n  margin-bottom: var(--GW-SPACING-4);\n  font-weight: normal !important;\n}\n\n.modalWidth {\n  width: var(--gw-modal-width);\n}\n\n.modalTitle {\n  font-weight: var(--font-weight-normal-bold);\n  font-size: var(--font-xxl);\n  padding-top: var(--gw-modal-title-padding-top);\n  padding-bottom: var(--gw-modal-title-padding-bottom);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"longDescription": "app__UWIssueDetailPopup__longDescription",
	"modalWidth": "app__UWIssueDetailPopup__modalWidth",
	"modalTitle": "app__UWIssueDetailPopup__modalTitle"
};
export default ___CSS_LOADER_EXPORT___;
