// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__PolicyDiffComponent__oldValue{color:var(--GW-TEXT-COLOR-2);margin-right:var(--GW-SPACING-4);text-decoration:line-through}.app__PolicyDiffComponent__newValue{font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD)}.app__PolicyDiffComponent__policyChangesSection:not(:last-child){margin-bottom:var(--GW-SPACING-6)}.app__PolicyDiffComponent__policyChangesSectionTitle{border-bottom:var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);padding:var(--GW-SPACING-5) 0}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-policyjob-react/components/PolicyDiffComponent/PolicyDiffComponent.module.scss"],"names":[],"mappings":"AAAA,oCACI,4BAAA,CAEA,gCAAA,CADA,4BACA,CAGJ,oCACI,2CAAA,CAGJ,iEACI,iCAAA,CAGJ,qDACI,iEAAA,CACA,6BAAA","sourcesContent":[".oldValue {\n    color: var(--GW-TEXT-COLOR-2);\n    text-decoration: line-through;\n    margin-right: var(--GW-SPACING-4);\n}\n\n.newValue {\n    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);\n}\n\n.policyChangesSection:not(:last-child) {\n    margin-bottom: var(--GW-SPACING-6);\n}\n\n.policyChangesSectionTitle {\n    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);\n    padding: var(--GW-SPACING-5) 0;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"oldValue": "app__PolicyDiffComponent__oldValue",
	"newValue": "app__PolicyDiffComponent__newValue",
	"policyChangesSection": "app__PolicyDiffComponent__policyChangesSection",
	"policyChangesSectionTitle": "app__PolicyDiffComponent__policyChangesSectionTitle"
};
export default ___CSS_LOADER_EXPORT___;
