// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ThankyouPagePolicyDocumentsComponent__h3Heading{margin-bottom:0!important}.app__ThankyouPagePolicyDocumentsComponent__policyDocumentMainDiv{margin-top:64px}.app__ThankyouPagePolicyDocumentsComponent__padding{margin-bottom:8px;margin-top:8px}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/ThankyouPagePolicyDocumentsComponent/ThankyouPagePolicyDocumentsComponent.module.scss"],"names":[],"mappings":"AAAA,sDACE,yBAAA,CAGF,kEACE,eAAA,CAGF,oDAEE,iBAAA,CADA,cACA","sourcesContent":[".h3Heading{\n  margin-bottom: 0px !important;\n}\n\n.policyDocumentMainDiv{\n  margin-top: 64px;\n}\n\n.padding {\n  margin-top: 8px;\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h3Heading": "app__ThankyouPagePolicyDocumentsComponent__h3Heading",
	"policyDocumentMainDiv": "app__ThankyouPagePolicyDocumentsComponent__policyDocumentMainDiv",
	"padding": "app__ThankyouPagePolicyDocumentsComponent__padding"
};
export default ___CSS_LOADER_EXPORT___;
