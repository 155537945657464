// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ManualPropertyLossComponent__currencyInput input[class=jut__CurrencyField__currencyInput]{border:none!important}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/ManualPropertyLossComponent/ManualPropertyLossComponent.module.scss"],"names":[],"mappings":"AAKI,gGACI,qBAAA","sourcesContent":[".currencyInput {\n    /* div[class='jut__CurrencyField__currencySymbol'] {*/\n    /*     // margin-right: var(--GW-SPACING-2) !important;*/\n    /* }*/\n\n    input[class='jut__CurrencyField__currencyInput'] {\n        border: none !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"currencyInput": "app__ManualPropertyLossComponent__currencyInput"
};
export default ___CSS_LOADER_EXPORT___;
