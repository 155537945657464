// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__UWIssuesComponent__uwIssuePage{padding:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-quoteandbind-eh-react/components/UWIssuesComponent/UWIssuesComponent.module.scss"],"names":[],"mappings":"AAAA,qCACI,2BAAA","sourcesContent":[".uwIssuePage {\n    padding: var(--GW-SPACING-3);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uwIssuePage": "app__UWIssuesComponent__uwIssuePage"
};
export default ___CSS_LOADER_EXPORT___;
