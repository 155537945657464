// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ThankyouPageRequiredDocumentsComponent__h3Heading{margin-bottom:0!important;margin-top:56px!important}.app__ThankyouPageRequiredDocumentsComponent__printUploadDoc{padding-top:0}.app__ThankyouPageRequiredDocumentsComponent__padding{margin-bottom:8px;margin-top:8px}.app__ThankyouPageRequiredDocumentsComponent__staticText{font-size:16px;margin-bottom:8px!important;text-align:justify;width:auto}.app__ThankyouPageRequiredDocumentsComponent__dataCellClass{overflow:visible;width:auto}.app__ThankyouPageRequiredDocumentsComponent__dataColumnClass{margin:0;padding:0}.app__ThankyouPageRequiredDocumentsComponent__headerText{padding:.25rem .8rem!important}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/ThankyouPageRequiredDocumentsComponent/ThankyouPageRequiredDocumentsComponent.module.scss"],"names":[],"mappings":"AACA,wDACE,yBAAA,CACA,yBAAA,CAGF,6DACE,aAAA,CAGF,sDAEE,iBAAA,CADA,cACA,CAGF,yDAIE,cAAA,CAHA,2BAAA,CAEA,kBAAA,CADA,UAEA,CAGF,4DAEE,gBAAA,CADA,UACA,CAGF,8DACE,QAAA,CACA,SAAA,CAGF,yDACE,8BAAA","sourcesContent":["\n.h3Heading{\n  margin-bottom: 0px !important;\n  margin-top: 56px !important;\n}\n\n.printUploadDoc {\n  padding-top: 0px;\n}\n\n.padding {\n  margin-top: 8px;\n  margin-bottom: 8px;\n}\n\n.staticText {\n  margin-bottom: 8px !important;\n  width:auto;\n  text-align: justify;\n  font-size: 16px;\n}\n\n.dataCellClass {\n  width: auto;\n  overflow: visible;\n}\n\n.dataColumnClass {\n  margin: 0px;\n  padding: 0px;\n}\n\n.headerText {\n  padding: 0.25rem 0.80rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h3Heading": "app__ThankyouPageRequiredDocumentsComponent__h3Heading",
	"printUploadDoc": "app__ThankyouPageRequiredDocumentsComponent__printUploadDoc",
	"padding": "app__ThankyouPageRequiredDocumentsComponent__padding",
	"staticText": "app__ThankyouPageRequiredDocumentsComponent__staticText",
	"dataCellClass": "app__ThankyouPageRequiredDocumentsComponent__dataCellClass",
	"dataColumnClass": "app__ThankyouPageRequiredDocumentsComponent__dataColumnClass",
	"headerText": "app__ThankyouPageRequiredDocumentsComponent__headerText"
};
export default ___CSS_LOADER_EXPORT___;
