// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__DiscountsListComponent__modifierTableClass{border:2px solid #d3d3d3;border-radius:1%;margin-bottom:var(--GW-SPACING-2)}.app__DiscountsListComponent__modifierTableRow{display:flex;font-family:var(--roboto-regular-font-family);font-size:16px;height:56px;justify-content:flex-start;padding:1%}.app__DiscountsListComponent__modifierTableRow:nth-child(2n){background-color:var(--msa-grey-200)}.app__DiscountsListComponent__modifierTableRowName{display:contents;float:left;font-weight:lighter;padding-left:2%}.app__DiscountsListComponent__modifierTotalRow{background-color:var(--msa-grey-200);border:2px solid #fff;border-radius:1%;padding:1%}.app__DiscountsListComponent__modifierValueClass{float:right}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/DiscountsListComponent/DiscountsListComponent.module.scss"],"names":[],"mappings":"AAAA,iDACE,wBAAA,CACA,gBAAA,CACA,iCAAA,CAGF,+CACE,YAAA,CAEA,6CAAA,CAGA,cAAA,CADA,WAAA,CAHA,0BAAA,CAEA,UAEA,CACA,6DACE,oCAAA,CAIJ,mDACE,gBAAA,CACA,UAAA,CAEA,mBAAA,CADA,eACA,CAGF,+CAEE,oCAAA,CACA,qBAAA,CACA,gBAAA,CAHA,UAGA,CAGF,iDACE,WAAA","sourcesContent":[".modifierTableClass {\n  border: 2px solid lightgray;\n  border-radius: 1%;\n  margin-bottom: var(--GW-SPACING-2);\n}\n\n.modifierTableRow {\n  display: flex;\n  justify-content: flex-start;\n  font-family: var(--roboto-regular-font-family);\n  padding: 1%;\n  height: 56px;\n  font-size: 16px;\n  &:nth-child(even) {\n    background-color: var(--msa-grey-200);\n  } \n}\n\n.modifierTableRowName {\n  display: contents;\n  float: left;\n  padding-left: 2%;\n  font-weight: lighter;\n}\n\n.modifierTotalRow {\n  padding: 1%;\n  background-color: var(--msa-grey-200);\n  border: 2px solid white;\n  border-radius: 1%;\n}\n\n.modifierValueClass {\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modifierTableClass": "app__DiscountsListComponent__modifierTableClass",
	"modifierTableRow": "app__DiscountsListComponent__modifierTableRow",
	"modifierTableRowName": "app__DiscountsListComponent__modifierTableRowName",
	"modifierTotalRow": "app__DiscountsListComponent__modifierTotalRow",
	"modifierValueClass": "app__DiscountsListComponent__modifierValueClass"
};
export default ___CSS_LOADER_EXPORT___;
