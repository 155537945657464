// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ProtectiveCheckboxGroup__protectiveCheckboxGroup,.app__ProtectiveCheckboxGroup__sprinklerHelpText{margin-bottom:var(--GW-SPACING-4);padding-bottom:12px}.app__ProtectiveCheckboxGroup__sprinklerHelpText{font-size:var(--GW-FONT-SIZE-SM)!important}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/EHProtectiveCheckboxGroupComponent/ProtectiveCheckboxGroup.module.scss"],"names":[],"mappings":"AAIA,wGAHI,iCAAA,CACA,mBAKA,CAHJ,iDACI,0CAEA","sourcesContent":[".protectiveCheckboxGroup{\n    margin-bottom: var(--GW-SPACING-4);\n    padding-bottom: 12px;    \n}\n.sprinklerHelpText{\n    font-size: var(--GW-FONT-SIZE-SM) !important;\n    margin-bottom: var(--GW-SPACING-4);\n    padding-bottom: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"protectiveCheckboxGroup": "app__ProtectiveCheckboxGroup__protectiveCheckboxGroup",
	"sprinklerHelpText": "app__ProtectiveCheckboxGroup__sprinklerHelpText"
};
export default ___CSS_LOADER_EXPORT___;
