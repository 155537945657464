// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__PriorPolicyComponent__checkbox{left:50%;position:relative;right:50%}.app__PriorPolicyComponent__carrierName{overflow:hidden}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/PriorPolicyComponent/PriorPolicyComponent.module.scss"],"names":[],"mappings":"AAAA,qCAEE,QAAA,CADA,iBAAA,CAEA,SAAA,CAGF,wCAEE,eAAA","sourcesContent":[".checkbox {\n  position: relative;\n  left: 50%;\n  right: 50%;\n}\n\n.carrierName {\n  /* To wrap content*/\n  overflow: hidden; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "app__PriorPolicyComponent__checkbox",
	"carrierName": "app__PriorPolicyComponent__carrierName"
};
export default ___CSS_LOADER_EXPORT___;
