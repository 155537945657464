// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__SupportingPolicyModal__gridBorder,.app__SupportingPolicyModal__gridHeaderBorder{border:1px solid var(--msa-grid-border-grey)}.app__SupportingPolicyModal__gridHeaderBorder{background-color:var(--msa-grid-bg-grey);border-bottom:none!important;margin-bottom:0!important}.app__SupportingPolicyModal__buttonContainer{display:flex;flex-direction:row}.app__SupportingPolicyModal__supportingModalDialog[role=dialog]{width:75%}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/SupportingPolicyComponent/SupportingPolicyModal/SupportingPolicyModal.module.scss"],"names":[],"mappings":"AAAA,sFACE,4CAAA,CAGF,8CAEE,wCAAA,CACA,4BAAA,CACA,yBAAA,CAGF,6CACE,YAAA,CACA,kBAAA,CAGF,gEACE,SAAA","sourcesContent":[".gridBorder {\n  border: solid 1px var(--msa-grid-border-grey);\n}\n\n.gridHeaderBorder {\n  @extend .gridBorder;\n  background-color: var(--msa-grid-bg-grey);\n  border-bottom: none !important;\n  margin-bottom: 0 !important;\n}\n\n.buttonContainer {\n  display: flex;\n  flex-direction: row;\n}\n\n.supportingModalDialog[role=\"dialog\"] {\n  width: 75%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridBorder": "app__SupportingPolicyModal__gridBorder",
	"gridHeaderBorder": "app__SupportingPolicyModal__gridHeaderBorder",
	"buttonContainer": "app__SupportingPolicyModal__buttonContainer",
	"supportingModalDialog": "app__SupportingPolicyModal__supportingModalDialog"
};
export default ___CSS_LOADER_EXPORT___;
