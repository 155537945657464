// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__E1POOSConflictsComponent__oosConflictsMainContainer{padding-top:var(--GW-SPACING-3)}.app__E1POOSConflictsComponent__overrideConflict{display:flex!important;flex-direction:row!important}.app__E1POOSConflictsComponent__overrideActionButton{margin-right:var(--GW-SPACING-4)}.app__E1POOSConflictsComponent__submitActionButton{margin-top:var(--GW-SPACING-4)}.app__E1POOSConflictsComponent__oosConflictMessage{font-weight:700}.app__E1POOSConflictsComponent__oosConflictOverrideNoneAllButtonsDiv{padding-top:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1POOSConflictsComponent/E1POOSConflictsComponent.module.scss"],"names":[],"mappings":"AAAA,0DACE,+BAAA,CAGF,iDACE,sBAAA,CACA,4BAAA,CAGF,qDACE,gCAAA,CAEF,mDACE,8BAAA,CAGF,mDACE,eAAA,CAGF,qEACE,+BAAA","sourcesContent":[".oosConflictsMainContainer {\n  padding-top: var(--GW-SPACING-3);\n}\n\n.overrideConflict {\n  display: flex !important;\n  flex-direction: row !important;\n}\n\n.overrideActionButton {\n  margin-right: var(--GW-SPACING-4);\n}\n.submitActionButton {\n  margin-top: var(--GW-SPACING-4);\n}\n\n.oosConflictMessage {\n  font-weight: bold;\n}\n\n.oosConflictOverrideNoneAllButtonsDiv {\n  padding-top: var(--GW-SPACING-3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"oosConflictsMainContainer": "app__E1POOSConflictsComponent__oosConflictsMainContainer",
	"overrideConflict": "app__E1POOSConflictsComponent__overrideConflict",
	"overrideActionButton": "app__E1POOSConflictsComponent__overrideActionButton",
	"submitActionButton": "app__E1POOSConflictsComponent__submitActionButton",
	"oosConflictMessage": "app__E1POOSConflictsComponent__oosConflictMessage",
	"oosConflictOverrideNoneAllButtonsDiv": "app__E1POOSConflictsComponent__oosConflictOverrideNoneAllButtonsDiv"
};
export default ___CSS_LOADER_EXPORT___;
