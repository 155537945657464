// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__EAVehiclePrefill__eaVehiclePrefillContainer .app__EAVehiclePrefill__width-800{width:var(--GW-TABLE-DIV-WIDTH)}.app__EAVehiclePrefill__eaVehiclePrefillContainer .app__EAVehiclePrefill__eaVehiclePreFillGrid>div{border:var(--GW-BORDER-WIDTH-THIN) solid var(--msa-grey-200);padding:var(--GW-SPACING-4)}.app__EAVehiclePrefill__eaVehiclePrefillContainer .app__EAVehiclePrefill__eaVehiclePreFillGrid>div:nth-child(2n){background-color:var(--msa-grey-200)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-quoteandbind-ea-react/pages/Vehicles/EAVehiclePrefill/EAVehiclePrefill.module.scss"],"names":[],"mappings":"AACE,oFACE,+BAAA,CAGF,mGACE,4DAAA,CACA,2BAAA,CAGF,iHACE,oCAAA","sourcesContent":[".eaVehiclePrefillContainer {\n  .width-800 {\n    width: var(--GW-TABLE-DIV-WIDTH);\n  }\n\n  .eaVehiclePreFillGrid > div {\n    border: var(--GW-BORDER-WIDTH-THIN) solid var(--msa-grey-200);\n    padding: var(--GW-SPACING-4);\n  }\n  \n  .eaVehiclePreFillGrid > div:nth-child(even) {\n    background-color: var(--msa-grey-200);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eaVehiclePrefillContainer": "app__EAVehiclePrefill__eaVehiclePrefillContainer",
	"width-800": "app__EAVehiclePrefill__width-800",
	"eaVehiclePreFillGrid": "app__EAVehiclePrefill__eaVehiclePreFillGrid"
};
export default ___CSS_LOADER_EXPORT___;
