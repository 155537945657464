// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__QuotePage__startDate{align-items:baseline;padding:0;width:auto}.app__QuotePage__guidelinesInfo{display:flex;position:relative;width:auto}.app__QuotePage__quoteProposal{display:flex;flex-direction:row;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-policy-view-common-react/pages/eh/common/PolicyViewQuotePage/QuotePage.module.scss"],"names":[],"mappings":"AAAA,2BAGI,oBAAA,CADA,SAAA,CADA,UAEA,CAEF,gCACE,YAAA,CAEA,iBAAA,CADA,UACA,CAEJ,+BACE,YAAA,CACA,kBAAA,CACA,wBAAA","sourcesContent":[".startDate {\n    width: auto;\n    padding: 0;\n    align-items: baseline;\n  }\n  .guidelinesInfo{\n    display: flex;\n    width: auto;\n    position: relative;\n}\n.quoteProposal {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"startDate": "app__QuotePage__startDate",
	"guidelinesInfo": "app__QuotePage__guidelinesInfo",
	"quoteProposal": "app__QuotePage__quoteProposal"
};
export default ___CSS_LOADER_EXPORT___;
