// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__E1PEATPIDisplayTableComponent__eaTPIDisplayContainer .app__E1PEATPIDisplayTableComponent__tpiTableCell>div,.app__E1PEATPIDisplayTableComponent__eaTPIDisplayContainer .app__E1PEATPIDisplayTableComponent__tpiTableHeader{font-size:var(--GW-FONT-SIZE-SM);overflow:visible;overflow-wrap:break-word;white-space:break-spaces!important}.app__E1PEATPIDisplayTableComponent__eaTPIDisplayContainer .app__E1PEATPIDisplayTableComponent__actionColumnOpacity button[class*=jut__ActionColumn__actionItem]{min-width:var(--GW-BUTTON-HEIGHT-SMALL);opacity:1!important}.app__E1PEATPIDisplayTableComponent__eaTPIDisplayContainer .app__E1PEATPIDisplayTableComponent__tpiTableActions{display:flex;flex-direction:row}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1PEATPIDisplayTableComponent/E1PEATPIDisplayTableComponent.module.scss"],"names":[],"mappings":"AASI,gOACE,gCAAA,CAGA,gBAAA,CADA,wBAAA,CADA,kCAEA,CAKF,iKAEE,uCAAA,CADA,mBACA,CAIJ,gHACE,YAAA,CACA,kBAAA","sourcesContent":[".eaTPIDisplayContainer {\n  .tpiTableHeader {\n    font-size: var(--GW-FONT-SIZE-SM);\n    white-space: break-spaces !important;\n    overflow-wrap: break-word;\n    overflow: visible;\n  }\n  \n  .tpiTableCell {\n    > div {\n      font-size: var(--GW-FONT-SIZE-SM);\n      white-space: break-spaces !important;\n      overflow-wrap: break-word;\n      overflow: visible;\n    }\n  }\n  \n  .actionColumnOpacity {\n    button[class*='jut__ActionColumn__actionItem'] {\n      opacity: 1 !important;\n      min-width: var(--GW-BUTTON-HEIGHT-SMALL);\n    }\n  }\n  \n  .tpiTableActions {\n    display: flex;\n    flex-direction: row;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eaTPIDisplayContainer": "app__E1PEATPIDisplayTableComponent__eaTPIDisplayContainer",
	"tpiTableCell": "app__E1PEATPIDisplayTableComponent__tpiTableCell",
	"tpiTableHeader": "app__E1PEATPIDisplayTableComponent__tpiTableHeader",
	"actionColumnOpacity": "app__E1PEATPIDisplayTableComponent__actionColumnOpacity",
	"tpiTableActions": "app__E1PEATPIDisplayTableComponent__tpiTableActions"
};
export default ___CSS_LOADER_EXPORT___;
