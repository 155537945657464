// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__NonOperatorComponent__popUp{background:#fff}.app__NonOperatorComponent__okButton{background:var(--msa-primary-blue);color:none;font-weight:var(--font-weight-bold)}.app__NonOperatorComponent__okButton,.app__NonOperatorComponent__okButton:active:focus,.app__NonOperatorComponent__okButton:focus{border:none}.app__NonOperatorComponent__accountsCancelButton{background:none;border:none;color:var(--msa-primary-blue);font-weight:var(--font-weight-bold)}.app__NonOperatorComponent__accountsCancelButton:active:focus,.app__NonOperatorComponent__accountsCancelButton:focus{border:none}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/NonOperatorComponent/NonOperatorComponent.module.scss"],"names":[],"mappings":"AAAA,kCACE,eAAA,CAGF,qCACE,kCAAA,CAGA,UAAA,CAFA,mCAEA,CAMA,kIACE,WAAA,CAIJ,iDACE,eAAA,CAEA,WAAA,CACA,6BAAA,CAFA,mCAEA,CAMA,qHACE,WAAA","sourcesContent":[".popUp {\n  background: white;\n}\n\n.okButton {\n  background: var(--msa-primary-blue);\n  font-weight: var(--font-weight-bold);\n  border: none;\n  color: none;\n\n  &:active:focus {\n    border: none;\n  }\n\n  &:focus {\n    border: none;\n  }\n}\n\n.accountsCancelButton {\n  background: none;\n  font-weight: var(--font-weight-bold);\n  border: none;\n  color: var(--msa-primary-blue);\n\n  &:active:focus {\n    border: none;\n  }\n\n  &:focus {\n    border: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popUp": "app__NonOperatorComponent__popUp",
	"okButton": "app__NonOperatorComponent__okButton",
	"accountsCancelButton": "app__NonOperatorComponent__accountsCancelButton"
};
export default ___CSS_LOADER_EXPORT___;
