// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__E1PPreferredTPIExistComponent__preferredTPIInfo{display:flex;flex-direction:column;margin:var(--GW-SPACING-6) 0}.app__E1PPreferredTPIExistComponent__messageTextStyle{font-size:var(--GW-FONT-SIZE)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1PPreferredTPIExistComponent/E1PPreferredTPIExistComponent.module.scss"],"names":[],"mappings":"AAAA,sDAEE,YAAA,CACA,qBAAA,CAFA,4BAEA,CAGF,sDACE,6BAAA","sourcesContent":[".preferredTPIInfo {\n  margin: var(--GW-SPACING-6) 0;\n  display: flex;\n  flex-direction: column;\n}\n\n.messageTextStyle {\n  font-size: var(--GW-FONT-SIZE);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preferredTPIInfo": "app__E1PPreferredTPIExistComponent__preferredTPIInfo",
	"messageTextStyle": "app__E1PPreferredTPIExistComponent__messageTextStyle"
};
export default ___CSS_LOADER_EXPORT___;
