// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__Reinstatement__tilesContainer{display:flex}.app__Reinstatement__pageTitle{font-size:40px}.app__Reinstatement__jobNumber{font-size:24px}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/Reinstatement/Reinstatement.module.scss"],"names":[],"mappings":"AACA,oCACI,YAAA,CAGJ,+BACI,cAAA,CAGJ,+BACI,cAAA","sourcesContent":["\n.tilesContainer {\n    display: flex;\n}\n\n.pageTitle {\n    font-size: 40px;\n}\n\n.jobNumber{\n    font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tilesContainer": "app__Reinstatement__tilesContainer",
	"pageTitle": "app__Reinstatement__pageTitle",
	"jobNumber": "app__Reinstatement__jobNumber"
};
export default ___CSS_LOADER_EXPORT___;
