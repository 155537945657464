// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__E1PTransactionActionsComponent__transactionActionContainer{display:flex;justify-content:flex-start;margin:var(--GW-SPACING-4) 0;text-align:right}.app__E1PTransactionActionsComponent__continueTransaction,.app__E1PTransactionActionsComponent__viewTransaction{margin-right:var(--GW-LAYOUT-4)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1PTransactionActionsComponent/E1PTransactionActionsComponent.module.scss"],"names":[],"mappings":"AAEA,iEAEE,YAAA,CACA,0BAAA,CACA,4BAAA,CAHA,gBAGA,CAOF,gHACE,+BAAA","sourcesContent":["@import '~@jutro/theme/assets/sass/helpers';\n\n.transactionActionContainer {\n  text-align: right;\n  display: flex;\n  justify-content: flex-start;\n  margin: var(--GW-SPACING-4) 0;\n}\n\n.continueTransaction {\n  margin-right: var(--GW-LAYOUT-4);\n}\n\n.viewTransaction {\n  margin-right: var(--GW-LAYOUT-4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transactionActionContainer": "app__E1PTransactionActionsComponent__transactionActionContainer",
	"continueTransaction": "app__E1PTransactionActionsComponent__continueTransaction",
	"viewTransaction": "app__E1PTransactionActionsComponent__viewTransaction"
};
export default ___CSS_LOADER_EXPORT___;
