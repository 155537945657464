// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__E1PSignatureComponent__sign-policy-button{margin-top:30px}.app__E1PSignatureComponent__sign-policy-iframe-close{float:right}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1PSignatureComponent/E1PSignatureComponent.module.scss"],"names":[],"mappings":"AAAA,gDACI,eAAA,CAGJ,sDACI,WAAA","sourcesContent":[".sign-policy-button {\n    margin-top: 30px;\n}\n\n.sign-policy-iframe-close {\n    float: right\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sign-policy-button": "app__E1PSignatureComponent__sign-policy-button",
	"sign-policy-iframe-close": "app__E1PSignatureComponent__sign-policy-iframe-close"
};
export default ___CSS_LOADER_EXPORT___;
