// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__HistoryComponent__pageHeader{margin:var(--GW-SPACING-4) 0}.app__HistoryComponent__compareButton{float:right}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/Policies/HistoryComponent/HistoryComponent.module.scss"],"names":[],"mappings":"AAAA,mCACI,4BAAA,CAGJ,sCACI,WAAA","sourcesContent":[".pageHeader {\n    margin: var(--GW-SPACING-4) 0;\n}\n\n.compareButton {\n    float: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": "app__HistoryComponent__pageHeader",
	"compareButton": "app__HistoryComponent__compareButton"
};
export default ___CSS_LOADER_EXPORT___;
