// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__E1PContactsFoundComponent__smallPopUp{border-radius:0;width:675px}.app__E1PContactsFoundComponent__smallPopUp div[class=jut__ModalFooter__modalFooter]>div{justify-content:end!important}.app__E1PContactsFoundComponent__contactsFoundContainer{margin-top:var(--GW-SPACING-10)}.app__E1PContactsFoundComponent__messageStyle{font-family:var(--roboto-medium-font-family)!important}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1PContactsFoundComponent/E1PContactsFoundComponent.module.scss"],"names":[],"mappings":"AAAA,4CAEI,eAAA,CADA,WACA,CACA,yFACE,6BAAA,CAIJ,wDACE,+BAAA,CAGF,8CACE,sDAAA","sourcesContent":[".smallPopUp {\n    width: 675px;\n    border-radius: 0px;\n    div[class='jut__ModalFooter__modalFooter'] > div {\n      justify-content: end !important;\n    }\n  }\n  \n  .contactsFoundContainer {\n    margin-top: var(--GW-SPACING-10);\n  }\n  \n  .messageStyle {\n    font-family: var(--roboto-medium-font-family) !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smallPopUp": "app__E1PContactsFoundComponent__smallPopUp",
	"contactsFoundContainer": "app__E1PContactsFoundComponent__contactsFoundContainer",
	"messageStyle": "app__E1PContactsFoundComponent__messageStyle"
};
export default ___CSS_LOADER_EXPORT___;
