// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__FieldLinkComponent__fieldLinkColor{color:var(--GW-LINK-COLOR);text-decoration:none}.app__FieldLinkComponent__fieldLinkColor:hover{color:var(--GW-LINK-COLOR-HOVER);text-decoration:none}", "",{"version":3,"sources":["webpack://./node_modules/gw-components-platform-react/FieldLinkComponent/FieldLinkComponent.module.scss"],"names":[],"mappings":"AAAA,yCACE,0BAAA,CACA,oBAAA,CAGF,+CACE,gCAAA,CACA,oBAAA","sourcesContent":[".fieldLinkColor  {\n  color: var(--GW-LINK-COLOR);\n  text-decoration: none;\n}\n\n.fieldLinkColor:hover {\n  color: var(--GW-LINK-COLOR-HOVER);\n  text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldLinkColor": "app__FieldLinkComponent__fieldLinkColor"
};
export default ___CSS_LOADER_EXPORT___;
