// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__MoreMenuComponent__headingButtonStyle{max-width:-moz-fit-content!important;max-width:fit-content!important;min-width:-moz-fit-content!important;min-width:fit-content!important;padding-left:var(--GW-SPACING-2);padding-right:var(--GW-SPACING-2)}.app__MoreMenuComponent__spanStyle{width:7rem}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/MoreMenuComponent/MoreMenuComponent.module.scss"],"names":[],"mappings":"AAAE,4CAEE,oCAAA,CAAA,+BAAA,CADA,oCAAA,CAAA,+BAAA,CAEA,gCAAA,CACA,iCAAA,CAGF,mCACE,UAAA","sourcesContent":["  .headingButtonStyle {\n    min-width: fit-content !important;\n    max-width: fit-content !important;\n    padding-left: var(--GW-SPACING-2);\n    padding-right: var(--GW-SPACING-2);\n  }\n\n  .spanStyle {\n    width: 7rem;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headingButtonStyle": "app__MoreMenuComponent__headingButtonStyle",
	"spanStyle": "app__MoreMenuComponent__spanStyle"
};
export default ___CSS_LOADER_EXPORT___;
