// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__CopyQuoteComponent__makeCopyTooltip div[class*=\"tippy-box jut__Tooltip__tooltip\"]{max-width:calc(var(--GW-SPACING-1)*25)!important;min-width:-moz-fit-content!important;min-width:fit-content!important}.app__CopyQuoteComponent__colorStyle{color:#00739d}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/CopyQuoteComponent/CopyQuoteComponent.module.scss"],"names":[],"mappings":"AACI,wFACE,gDAAA,CACA,oCAAA,CAAA,+BAAA,CAIN,qCACE,aAAA","sourcesContent":[".makeCopyTooltip {\n    div[class*='tippy-box jut__Tooltip__tooltip'] {\n      max-width: calc(var(--GW-SPACING-1) * 25) !important;\n      min-width: fit-content !important;\n    }\n  }\n\n.colorStyle {\n  color: #00739d\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"makeCopyTooltip": "app__CopyQuoteComponent__makeCopyTooltip",
	"colorStyle": "app__CopyQuoteComponent__colorStyle"
};
export default ___CSS_LOADER_EXPORT___;
