// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media print{.app__PrintComponent__noPrint{display:none!important}.app__PrintComponent__preserveAncestor{border:none!important;box-shadow:none!important;display:block!important;padding:0!important;width:100%!important}.app__PrintComponent__preserveAncestor,.app__PrintComponent__preservePrint{margin:0!important}}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/PrintComponent/PrintComponent.module.scss"],"names":[],"mappings":"AAAA,aAEE,8BACE,sBAAA,CAGF,uCAKE,qBAAA,CACA,yBAAA,CALA,uBAAA,CAGA,mBAAA,CAFA,oBAIA,CAGF,2EANE,kBAOA,CAAA","sourcesContent":["@media print {\n\n  .noPrint {\n    display: none !important;\n  }\n\n  .preserveAncestor {\n    display: block !important;\n    width: 100% !important;\n    margin: 0 !important;\n    padding: 0 !important;\n    border: none !important;\n    box-shadow: none !important;\n  }\n\n  .preservePrint {\n    margin: 0 !important;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noPrint": "app__PrintComponent__noPrint",
	"preserveAncestor": "app__PrintComponent__preserveAncestor",
	"preservePrint": "app__PrintComponent__preservePrint"
};
export default ___CSS_LOADER_EXPORT___;
