// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--gw-select-box-style-width:20rem;--gw-select-box-style-max-width:100%}.app__SelectProducerCode__gwSelectBoxStyle{grid-template-columns:0fr auto;max-width:100%;max-width:var(--gw-select-box-style-max-width);width:20rem;width:var(--gw-select-box-style-width)}", "",{"version":3,"sources":["webpack://./node_modules/gw-gateway-common-react/components/SelectProducerCode/SelectProducerCode.module.scss"],"names":[],"mappings":"AAAA,MACI,iCAAA,CACA,oCAAA,CAEJ,2CACI,8BAAA,CAEA,cAAA,CAAA,8CAAA,CADA,WAAA,CAAA,sCACA","sourcesContent":[":root {\n    --gw-select-box-style-width: 20rem;\n    --gw-select-box-style-max-width: 100%;\n}\n.gwSelectBoxStyle {\n    grid-template-columns: 0fr auto;\n    width: var(--gw-select-box-style-width);\n    max-width: var(--gw-select-box-style-max-width);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gwSelectBoxStyle": "app__SelectProducerCode__gwSelectBoxStyle"
};
export default ___CSS_LOADER_EXPORT___;
