// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__E1PPageHeaderCompletionStatusComponent__checkCircleIcon{color:var(--msa-success);font-size:24px}.app__E1PPageHeaderCompletionStatusComponent__checkCircleIconGrey{color:var(--msa-grey-700);font-size:24px}.app__E1PPageHeaderCompletionStatusComponent__fieldSectionLabelSpan{align-content:left;font-weight:400;width:-moz-fit-content;width:fit-content}.app__E1PPageHeaderCompletionStatusComponent__headerFieldGrid{display:flex}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/E1PPageHeaderCompletionStatusComponent/E1PPageHeaderCompletionStatusComponent.module.scss"],"names":[],"mappings":"AAAA,8DAEE,wBAAA,CADA,cACA,CAEF,kEAEE,yBAAA,CADA,cACA,CAEF,oEACE,kBAAA,CAEA,eAAA,CADA,sBAAA,CAAA,iBACA,CAEF,8DACE,YAAA","sourcesContent":[".checkCircleIcon {\n  font-size: 24px;\n  color: var(--msa-success);\n}\n.checkCircleIconGrey {\n  font-size: 24px;\n  color: var(--msa-grey-700);\n}\n.fieldSectionLabelSpan {\n  align-content: left;\n  width: fit-content;\n  font-weight: normal;\n}\n.headerFieldGrid {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkCircleIcon": "app__E1PPageHeaderCompletionStatusComponent__checkCircleIcon",
	"checkCircleIconGrey": "app__E1PPageHeaderCompletionStatusComponent__checkCircleIconGrey",
	"fieldSectionLabelSpan": "app__E1PPageHeaderCompletionStatusComponent__fieldSectionLabelSpan",
	"headerFieldGrid": "app__E1PPageHeaderCompletionStatusComponent__headerFieldGrid"
};
export default ___CSS_LOADER_EXPORT___;
