// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__JobSearchCriteriaFilterComponent__filterGridStyle [class*=jut__FieldLabel__top]{margin-bottom:0!important}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/Components/E1PPoliciesDashboardComponent/JobSearchCriteriaFilterComponent/JobSearchCriteriaFilterComponent.module.scss"],"names":[],"mappings":"AACC,sFACG,yBAAA","sourcesContent":[".filterGridStyle {\n [class*='jut__FieldLabel__top'] {\n    margin-bottom: 0px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterGridStyle": "app__JobSearchCriteriaFilterComponent__filterGridStyle"
};
export default ___CSS_LOADER_EXPORT___;
